import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import OrdersForm from "./OrdersForm";

const OrdersNew = () => {
  const params = useParams();
  return (
    <Container fluid="lg">
      <h1>Ny order</h1>
      <hr />
      <OrdersForm customerId={params.customerId} />
    </Container>
  );
};

export default OrdersNew;
