import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ConnectVismaService, DataSourceType } from "../services/openapi";

const Vismaeaccountingcallback = () => {
  const [result, setResult] = useState("not configured");
  const [searchParams] = useSearchParams();
  var code = searchParams.get("code");
  var dataSourceType: DataSourceType | null = searchParams.get(
    "state"
  ) as DataSourceType;

  useEffect(() => {
    if (code && dataSourceType) {
      ConnectVismaService.getVismaeaccountingcallback(
        code,
        dataSourceType
      ).then((response) => {
        setResult(response);
      });
    }
  }, [code, dataSourceType]);

  return <p>{result}</p>;
};

export default Vismaeaccountingcallback;
