/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum DataSourceType {
    ACCOUNTING_SOFTWARE = 'AccountingSoftware',
    QUOTE = 'Quote',
    ORDER = 'Order',
    INVOICE = 'Invoice',
    CUSTOMER = 'Customer',
    ARTICLE = 'Article',
    ADDRESS = 'Address',
    CONTACT = 'Contact',
}
