import "./App.css";
import {
  MsalProvider,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import {
  AuthenticationResult,
  PublicClientApplication,
} from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import Home from "./Components/Home";
import Layout from "./Components/Layout";
import NavMenuSignedIn from "./Components/NavMenuSignedIn";
import Dashboard from "./Components/Dashboard";
import { Route } from "react-router";
import { Routes } from "react-router-dom";
import CompanySettings from "./Components/CompanySettings";
import NavMenu from "./Components/NavMenu";
import Customers from "./Components/Customer/Customers";
import CustomersDetails from "./Components/Customer/CustomersDetails";
// import Quotes from "./Components/Quotes";
import Vismaeaccountingcallback from "./Components/Vismaeaccountingcallback";
import NavMenuBottom from "./Components/NavMenuBottom";
import Fortnoxcallback from "./Components/Fortnoxcallback";
import { AppVariableContextProvider } from "./AppVariableContextProvider";
import React from "react";
import OrdersDetails from "./Components/Order/OrdersDetails";
import OrdersEdit from "./Components/Order/OrdersEdit";
import OrdersNew from "./Components/Order/OrdersNew";
import Orders from "./Components/Order/Orders";
import Invoices from "./Components/Invoice/Invoices";
import InvoicesDetails from "./Components/Invoice/InvoiceDetails";
import { OpenAPI } from "./services/openapi";
import { msalGetToken } from "./Utils/msalGetToken";

const pca = new PublicClientApplication(msalConfig);
var account = pca.getAllAccounts()[0];

OpenAPI.BASE = "https://data.sella.se";
OpenAPI.TOKEN = () => msalGetToken(pca);

function selectAccount() {
  /**
   * See here for more info on account retrieval:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
   */

  const currentAccounts = pca.getAllAccounts();

  if (currentAccounts.length === 0) {
    console.warn("No accounts detected.");
    return;
  } else if (currentAccounts.length > 1) {
    console.warn("Multiple accounts detected.");
    return;
  } else if (currentAccounts.length === 1) {
    return;
  }
}

function handleResponse(response: AuthenticationResult | null) {
  if (response !== null) {
    pca.setActiveAccount(response.account);
  } else {
    selectAccount();
  }
}

pca
  .handleRedirectPromise()
  .then(handleResponse)
  .catch((error) => {
    console.error("HandleRedirectResponse", error);
  });

function App() {
  return (
    <MsalProvider instance={pca}>
      <UnauthenticatedTemplate>
        <NavMenu />
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
          </Routes>
        </Layout>
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate localAccountId={account?.localAccountId}>
        <AppVariableContextProvider>
          <React.Fragment>
            <NavMenuSignedIn />
            <Layout>
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/companysettings" element={<CompanySettings />} />
                <Route path="/invoices" element={<Invoices />} />
                <Route
                  path="/invoices/details/:id"
                  element={<InvoicesDetails />}
                />
                <Route path="/orders" element={<Orders />} />
                <Route path="/orders/details/:id" element={<OrdersDetails />} />
                <Route path="/orders/new/:customerid" element={<OrdersNew />} />
                <Route path="/orders/new" element={<OrdersNew />} />
                <Route path="/orders/edit/:id" element={<OrdersEdit />} />
                {/* <Route path="/quotes" element={<Quotes />} /> */}
                {/* <Route path="/quotes/details/:id" element={<QuotesDetails />} /> */}
                <Route path="/customers" element={<Customers />} />
                <Route
                  path="/customers/details/:id"
                  element={<CustomersDetails />}
                />
                <Route
                  path="/vismaeaccountingcallback"
                  element={<Vismaeaccountingcallback />}
                />
                <Route path="/fortnoxcallback" element={<Fortnoxcallback />} />
                <Route
                  path="/callback"
                  element={<Vismaeaccountingcallback />}
                />
              </Routes>
            </Layout>
            <NavMenuBottom />
          </React.Fragment>
        </AppVariableContextProvider>
      </AuthenticatedTemplate>
    </MsalProvider>
  );
}

export default App;
