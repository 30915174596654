import { faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Row, Col, Form, InputGroup, Button } from "react-bootstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
// import { Typeahead } from "react-bootstrap-typeahead"; // ES2015
// import PropTypes from "prop-types";
import {
  UseFormRegister,
  FieldErrorsImpl,
  UseFormGetValues,
  UseFormSetValue,
} from "react-hook-form";
import { Address, AddressesService, Order } from "../services/openapi";

interface AddressProps {
  recipientFieldLabel: string;
  header?: string;
  fieldname?: string;
  register: UseFormRegister<any>;
  errors: Partial<FieldErrorsImpl<any>>;
  AddressId?: string;
  getValues: UseFormGetValues<Order>;
  setValues: UseFormSetValue<Order>;
}

const AddressInput = (props: AddressProps) => {
  var prefix = props.fieldname ? props.fieldname + "." : "";
  const [recipientKey, setRecipientKey] = useState(crypto.randomUUID());
  const [address1Key, setAddress1Key] = useState(crypto.randomUUID());

  const [isLoadingAddress, setIsLoadingAddress] = useState(false);
  const [Addresses, setAddresses] = useState<Address[]>([]);
  const filterBy = () => true;

  const handleSearch = (recipientSearch: string, addressSearch: string) => {
    setIsLoadingAddress(true);
    var customerNr = props.getValues("customerId");
    var recipient = recipientSearch ?? "";
    var address = addressSearch ?? "";
    if (customerNr) {
      AddressesService.getApiV1Addresses(
        1,
        10,
        customerNr,
        recipient,
        address
      ).then((result) => {
        if (result.data) {
          //filter data to remove addresses without address 1 field if address search is not empty
          if (addressSearch != "") {
            var filtered = result.data.filter(
              (x) => x.address1 != null && x.address1 != ""
            );
            //replace null with empty string for recipient
            filtered.forEach((x) => {
              if (x.recipient == null) x.recipient = "";
            });
            setAddresses(filtered);
          }
          //filter data to remove addresses without recipient if recipient search is not  empty
          else if (recipientSearch != "") {
            var filtered2 = result.data.filter(
              (x) => x.recipient != null && x.recipient != ""
            );
            //replace null with empty string for address 1
            filtered2.forEach((x) => {
              if (x.address1 == null) x.address1 = "";
            });
            setAddresses(filtered2);
          }
        } else {
          setAddresses([]);
        }
        setIsLoadingAddress(false);
      });
    }
  };

  const handleDelete = (id: string) => {
    AddressesService.deleteApiV1Addresses(id).then((result) => {
      if (result) {
        //remove result.data from Addresses
        setAddresses(Addresses.filter((a) => a.id !== id));
      }
    });
  };

  const handleSearchRecipients = (query: string) => {
    handleSearch(query, "");
  };

  const handleSearchAddresses = (query: string) => {
    handleSearch("", query);
  };

  const handleAddressChange = (selected: Address) => {
    var addressfield = props.fieldname ? props.fieldname + "." : ("" as any);
    props.setValues(addressfield, selected);
    props.getValues(addressfield);
    RemountInputs();
  };

  const handleManualChange = (fieldname: string, value: string) => {
    var addressfield = (prefix + fieldname) as "customerName";
    props.setValues(addressfield, value);
    props.getValues(addressfield);
    props.setValues((prefix + "id") as "deliveryAddress.id", null);
  };

  const AddressOptionListItem = (option: Address) => (
    <div className="d-flex justify-content-between">
      <div className="">
        <span>{option.recipient}</span>
        <br />
        <span>{option.address1}</span>
        <br />
        <span>{option.address2}</span>
        <br />
        <span>
          {option.city} {option.postalCode}
        </span>
        <br />
      </div>
      <div className="d-flex align-items-center">
        {option.id && (
          <Button
            variant="danger"
            size="sm"
            onClick={(e) => {
              e.stopPropagation();
              handleDelete(option.id ?? "");
            }}
          >
            <FontAwesomeIcon className="danger" icon={faTrash} />
          </Button>
        )}
      </div>
    </div>
  );

  const RemountInputs = () => {
    setRecipientKey(crypto.randomUUID());
    setAddress1Key(crypto.randomUUID());
  };

  return (
    <Row className="mb-2">
      <h5>{props.header}</h5>
      <Col md="6">
        <Form.Group>
          <Form.Group>
            {props.AddressId}
            <InputGroup className="mb-2">
              <InputGroup.Text id="basic-addon-2">
                {props.recipientFieldLabel}
                <FontAwesomeIcon className="ps-1" icon={faSearch} />
              </InputGroup.Text>
              <AsyncTypeahead
                inputProps={{ autoComplete: "none" }}
                id={props.fieldname}
                options={Addresses}
                isLoading={isLoadingAddress}
                onSearch={handleSearchRecipients}
                filterBy={filterBy}
                labelKey={"recipient"}
                onChange={(selected) => {
                  handleAddressChange(selected[0] as Address);
                }}
                onInputChange={(text) => handleManualChange("recipient", text)}
                defaultInputValue={
                  (props.getValues((prefix + "recipient") as any) as string) ??
                  ""
                }
                useCache={false}
                // @ts-ignore
                renderMenuItemChildren={(option: Address) => (
                  <AddressOptionListItem {...option} />
                )}
                placeholder="Sök eller ange organisation..."
                searchText="Söker..."
                emptyLabel="Inga träffar"
                key={recipientKey}
              />
            </InputGroup>
          </Form.Group>
          <InputGroup className="mb-2">
            <InputGroup.Text id="basic-addon-2">
              Adress 1
              <FontAwesomeIcon className="ps-1" icon={faSearch} />
            </InputGroup.Text>
            <AsyncTypeahead
              id={props.fieldname}
              options={Addresses}
              isLoading={isLoadingAddress}
              onSearch={handleSearchAddresses}
              filterBy={filterBy}
              labelKey={"address1"}
              onChange={(selected) => {
                handleAddressChange(selected[0] as Address);
              }}
              defaultInputValue={
                (props.getValues((prefix + "address1") as any) as string) ?? ""
              }
              onInputChange={(text) => {
                props.setValues(
                  (prefix + "address1") as "deliveryAddress.address1",
                  text
                );
              }}
              useCache={false}
              // @ts-ignore
              renderMenuItemChildren={(option: Address) => (
                <AddressOptionListItem {...option} />
              )}
              placeholder="Sök eller ange adress..."
              searchText="Söker..."
              emptyLabel="Inga träffar"
              key={address1Key}
            />
            <Form.Control.Feedback type="invalid">
              {props.errors[`${prefix}.address1`]?.message?.toString()}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group>
          <InputGroup className="mb-2">
            <InputGroup.Text id="basic-addon-2">Adress 2</InputGroup.Text>
            <Form.Control
              className="rounded-end"
              {...props.register(`${prefix}.address2`)}
              isInvalid={!!props.errors[`${prefix}.address2`]}
            />
            <Form.Control.Feedback type="invalid">
              {props.errors[`${prefix}.address2`]?.message?.toString()}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </Col>
      <Col md="6">
        <Form.Group>
          <InputGroup className="mb-2">
            <InputGroup.Text id="basic-addon-2">Ort</InputGroup.Text>
            <Form.Control
              autoComplete="none"
              className="rounded-end"
              {...props.register(`${prefix}.city`)}
              isInvalid={!!props.errors[`${prefix}.city`]}
            />
            <Form.Control.Feedback type="invalid">
              {props.errors[`${prefix}.city`]?.message?.toString()}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group>
          <InputGroup className="mb-2">
            <InputGroup.Text id="basic-addon-2">Postnummer</InputGroup.Text>
            <Form.Control
              autoComplete="none"
              className="rounded-end"
              {...props.register(`${prefix}.postalCode`)}
              isInvalid={!!props.errors[`${prefix}.postalCode`]}
            />
            <Form.Control.Feedback type="invalid">
              {props.errors[`${prefix}.postalCode`]?.message?.toString()}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </Col>
    </Row>
  );
};

export default AddressInput;
