import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";

import { Order, OrdersService } from "../../services/openapi";
import { ArticleRowTable } from "../ArticleRowTable";
import { ContactDetails } from "../ContactDetails";
import OrderEmailButton from "./OrderEmailButton";
import OrderPrintDropdown from "./OrderPrintDropdown";

function OrdersDetails() {
  const params = useParams();
  let navigate = useNavigate();

  const [data, setData] = useState<Order>();
  const [isCreatingInvoice, setIsCreatingInvoice] = useState(false);

  useEffect(() => {
    if (params.id) {
      OrdersService.getApiV1Orders1(params.id).then((response) => {
        setData(response);
      });
    }
  }, [params.id]);

  const CreateInvoice = () => {
    if (data && data.documentNumber) {
      setIsCreatingInvoice(true);
      OrdersService.postApiV1OrdersInvoice([data.documentNumber]).then(
        (response) => {
          navigate("/invoices/details/" + response.documentNumber);
        }
      );
    }
  };

  const MarkAsDelivered = () => {
    if (data && data.documentNumber) {
      data.isDelivered = true;
      OrdersService.putApiV1Orders(data.documentNumber, data).then(
        (response) => {
          setData(response);
        }
      );
    }
  };

  return (
    <Container fluid="xxl">
      <h1>Order: {data?.documentNumber}</h1>
      {data && data.documentNumber ? (
        <>
          <Row className="mb-3 mt-3">
            <Col className="d-flex">
              <OrderPrintDropdown orderId={data.documentNumber} />
              <OrderEmailButton orderId={data.documentNumber} />
              {!data.externalInvoiceReference && (
                <div className="d-flex w-100">
                  <Link to={"/orders/edit/" + params.id} className="me-auto">
                    <Button size="sm" className="ms-1" variant="light">
                      Ändra
                    </Button>
                  </Link>
                  {!data?.isDelivered && (
                    <Button
                      size="sm"
                      className="me-1"
                      variant="light"
                      onClick={() => MarkAsDelivered()}
                    >
                      Markera som levererad
                    </Button>
                  )}
                  <Button
                    variant="success"
                    size="sm"
                    onClick={() => CreateInvoice()}
                    disabled={isCreatingInvoice}
                  >
                    Skapa faktura
                  </Button>
                </div>
              )}
            </Col>
          </Row>
          <Card className="mb-3">
            <Card.Body>
              <Row>
                <Col md="6">
                  <Form.Check
                    label="Levererad"
                    id={`disabled-default-check`}
                    readOnly
                    checked={data.isDelivered === true}
                  />

                  <Form.Check
                    label="Makulerad"
                    id={`disabled-default-check`}
                    readOnly
                    checked={data.cancelled === true}
                  />

                  <Form.Check
                    label="Fakura skapad"
                    readOnly
                    id={`disabled-default-check`}
                  >
                    <Form.Check.Input
                      type="checkbox"
                      readOnly
                      checked={!!data.externalInvoiceReference}
                    />
                    <Form.Check.Label>Faktura skapad </Form.Check.Label>
                    <Link
                      className="ms-1"
                      to={"/invoices/details/" + data.externalInvoiceReference}
                    >
                      {data.externalInvoiceReference}
                    </Link>
                  </Form.Check>
                </Col>
                <Col md="6">
                  <p className="whitespace-pre">
                    <b>Anteckning: </b>
                    <br />
                    {data.comments}
                  </p>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md={6}>
                  <p>
                    <b>Ordernummer:</b> {data.documentNumber}
                  </p>
                  <p>
                    <b>Orderdatum:</b>{" "}
                    {data.orderDate?.toString().substring(0, 10)}
                  </p>
                  <p>
                    {" "}
                    <b>Kund: </b>
                    <Link to={"/customers/details/" + data.customerId}>
                      {data.customerName}
                    </Link>
                  </p>
                  <span>
                    <b>Ert referensnummer:</b>{" "}
                    {data.extensionProperties?.yourReferenceCode}
                  </span>
                </Col>
                <Col md={6}>
                  <p>
                    <b>Er referens:</b> {data.yourReference}{" "}
                  </p>
                  <p>
                    <b>Vår referens:</b> {data.ourReference}{" "}
                  </p>
                  <p>
                    <b>Fakturanummer:</b> {data.externalInvoiceReference}{" "}
                  </p>
                  <p className="mt-2">
                    <b>Valuta:</b> {data.currencyCode}
                  </p>
                  <span className="mt-2">
                    <b>Prislista:</b> {data.priceList}
                  </span>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Row>
            <Col>
              <h2>Upplockning</h2>
              <Card>
                <Card.Body>
                  <p>
                    <b>Datum: </b>
                    {data.extensionProperties?.lastPickUpDate
                      ? data.extensionProperties?.pickUpDate +
                        " - " +
                        data.extensionProperties?.lastPickUpDate
                      : data.extensionProperties?.pickUpDate}
                  </p>

                  <p>
                    <b>Tid: </b>
                    {(!!data.extensionProperties?.pickUpAfterTime ||
                      !!data.extensionProperties?.pickUpBeforeTime) &&
                      data.extensionProperties?.pickUpAfterTime +
                        " - " +
                        data.extensionProperties?.pickUpBeforeTime}
                  </p>
                  <p>
                    <b>Organisation:</b>{" "}
                    {data.extensionProperties?.pickupAddress?.recipient}
                  </p>
                  <p>
                    <b>Adress:</b>{" "}
                    {data.extensionProperties?.pickupAddress?.address1}
                  </p>
                  <p>
                    <b>Adress2:</b>{" "}
                    {data.extensionProperties?.pickupAddress?.address2}
                  </p>
                  <p>
                    <b>Postnummer:</b>{" "}
                    {data.extensionProperties?.pickupAddress?.postalCode}
                  </p>
                  <p>
                    <b>Ort:</b> {data.extensionProperties?.pickupAddress?.city}
                  </p>
                  <ContactDetails
                    contact={data.extensionProperties?.pickUpContact}
                  />
                  <span>
                    <b>Anvisning:</b>{" "}
                    {data.extensionProperties?.pickUpInstructions}
                  </span>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <h2>Leverans</h2>
              <Card className="mb-3">
                <Card.Body>
                  <p>
                    <b>Datum: </b>
                    {data.extensionProperties?.lastDeliveryDate
                      ? data.deliveryDate +
                        " - " +
                        data.extensionProperties?.lastDeliveryDate
                      : data.deliveryDate}
                  </p>
                  <p>
                    <b>Tid: </b>
                    {(!!data.extensionProperties?.deliveryAfterTime ||
                      !!data.extensionProperties?.deliveryBeforeTime) &&
                      data.extensionProperties?.deliveryAfterTime +
                        " - " +
                        data.extensionProperties?.deliveryBeforeTime}
                  </p>
                  <p>
                    <b>Organisation:</b> {data.deliveryAddress?.recipient}
                  </p>
                  <p>
                    <b>Adress: </b>
                    {data.deliveryAddress?.address1}
                  </p>
                  <p>
                    <b>Adress2: </b>
                    {data.deliveryAddress?.address2}
                  </p>
                  <p>
                    <b>Postnummer:</b> {data.deliveryAddress?.postalCode}
                  </p>
                  <p>
                    <b>Ort:</b> {data.deliveryAddress?.city}
                  </p>
                  <ContactDetails
                    contact={data.extensionProperties?.deliveryContact}
                  />
                  <span>
                    <b>Anvisning:</b>{" "}
                    {data.extensionProperties?.deliveryInstructions}
                  </span>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <span>
                    <b>Bruttovikt (Kg):</b> {data.grossWeightKg}
                  </span>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="mt-3">
              <h2>Artikelrader</h2>
              {data.rows && <ArticleRowTable data={data.rows} />}
            </Col>
          </Row>

          <Row>
            <Col className="text-end">
              <h2 className="fw-light">
                Total Exl. Moms: {data.amountVatExcluded} {data.currencyCode}
              </h2>
            </Col>
          </Row>
          {/* <Row>
            <Col className="text-end">
              <h5 className="fw-light">
                Moms: {data.totalVAT} {data.currencyCode}
              </h5>
            </Col>
          </Row>
          <Row>
            <Col className="text-end fw-light">
              <h5>
                <strong>Total inkl. moms: </strong>
                {data.amount} {data.currencyCode}
              </h5>
            </Col>
          </Row> */}
        </>
      ) : (
        <Row className="d-flex justify-content-center mt-4">
          <Spinner animation="border" variant="primary" />
        </Row>
      )}
    </Container>
  );
}

OrdersDetails.propTypes = {
  id: PropTypes.string,
};

export default OrdersDetails;
