/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AppVariables } from '../models/AppVariables';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AppVariablesService {

    /**
     * @returns AppVariables Success
     * @throws ApiError
     */
    public static getApiV1AppVariables(): CancelablePromise<AppVariables> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/AppVariables',
        });
    }

    /**
     * @param requestBody 
     * @returns AppVariables Success
     * @throws ApiError
     */
    public static putApiV1AppVariables(
requestBody?: AppVariables,
): CancelablePromise<AppVariables> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/AppVariables',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
