// import React from "react";

import {
  //   Column,
  //   Table,
  ColumnDef,
  RowData,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";

import { faAdd, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Table as BootstrapTable, Button } from "react-bootstrap";
import {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { Article, Order, OrderRow, PriceService } from "../services/openapi";
import ArticleSearchInput from "./ArticleSearchInput";

declare module "@tanstack/react-table" {
  interface TableMeta<TData extends RowData> {
    updateData: (rowIndex: number, columnId: string, value: unknown) => void;
  }
}

// Give our default column cell renderer editing superpowers!

export interface ArticleRowTableProps {
  arrayName: string;
  register: UseFormRegister<Order>;
  getValues: UseFormGetValues<Order>;
  setValue: UseFormSetValue<Order>;
  watch: UseFormWatch<Order>;
}

export const ArticleRowEditTable = (props: ArticleRowTableProps) => {
  const [data, setData] = useState<OrderRow[]>([]);
  var rows = props.getValues("rows");
  console.log("rows", rows);
  if (rows) {
    if (rows.length != data.length) {
      console.log("rows changed");
      setData(rows);
    }
  }

  if (!data) {
    return <div>No data</div>;
  }

  const defaultColumn: Partial<ColumnDef<OrderRow>> = {
    cell: ({ row: { index }, column: { id } }) => {
      return (
        <input
          className="form-control rounded-0 "
          onInput={() => {
            props.getValues(
              `${props.arrayName}.${index}.deliveredQuantity` as any
            );
            props.getValues(`${props.arrayName}.${index}.unitPrice` as any);
          }}
          {...props.register(`${props.arrayName}.${index}.${id}` as any)}
        />
      );
    },
  };
  const columnHelper = createColumnHelper<OrderRow>();

  const columns = [
    columnHelper.accessor("articleId", {
      header: () => <span className="col-2">Artikelnr</span>,
      size: 80,
      cell: ({ row: { index } }) => {
        return (
          <ArticleSearchInput
            id={index.toString()}
            onArticleSelected={(article) => {
              SelectArticle(article, index);
            }}
            selectedArticleId={props.getValues(
              `${props.arrayName}.${index}.articleId` as "rows.0.articleId"
            )}
          />
        );
      },
    }),
    columnHelper.accessor("text", {
      header: () => <span className="col-4">Beskrivning</span>,
    }),
    columnHelper.accessor("extensionProperties.machineNumber", {
      header: () => <span>Maskinnr</span>,
      cell: ({ row: { index } }) => {
        return (
          <textarea
            className="form-control rounded-0 "
            {...props.register(
              `${props.arrayName}.${index}.extensionProperties.machineNumber` as any
            )}
          />
        );
      },
    }),
    columnHelper.accessor("extensionProperties.bwCounts", {
      header: () => <span>Räkneverk S/V</span>,
      cell: ({ row: { index } }) => {
        return (
          <textarea
            className="form-control rounded-0 "
            {...props.register(
              `${props.arrayName}.${index}.extensionProperties.bwCounts` as any
            )}
          />
        );
      },
    }),
    columnHelper.accessor("extensionProperties.colorCounts", {
      header: () => <span>Räkneverk färg</span>,
      cell: ({ row: { index } }) => {
        return (
          <textarea
            className="form-control rounded-0"
            {...props.register(
              `${props.arrayName}.${index}.extensionProperties.colorCounts` as any
            )}
          />
        );
      },
    }),
    columnHelper.accessor("quantity", {
      header: () => <span className="col-1">Antal</span>,
      size: 50,
      cell: ({ row: { index } }) => {
        return (
          <input
            className="form-control rounded-0 text-end"
            {...props.register(`${props.arrayName}.${index}.quantity` as any, {
              onChange: (e) => {
                props.setValue(
                  `${props.arrayName}.${index}.quantity` as any,

                  e.target.value.replace(",", ".")
                );
                HandleQuantityChange(index, e.target.value);
              },
            })}
          />
        );
      },
    }),

    columnHelper.accessor("deliveredQuantity", {
      header: () => <span>Lev. antal</span>,
      size: 70,
      cell: ({ row: { index } }) => {
        return (
          <input
            className="form-control rounded-0 text-end"
            {...props.register(
              `${props.arrayName}.${index}.deliveredQuantity` as any,
              {
                onChange: (e) => {
                  props.setValue(
                    `${props.arrayName}.${index}.deliveredQuantity` as any,
                    e.target.value.replace(",", ".")
                  );
                  HandleTotalChange(index);
                },
              }
            )}
          />
        );
      },
    }),
    columnHelper.accessor("unit", {
      header: () => <span>Enhet</span>,
      size: 70,
      cell: ({ row: { index } }) => {
        return (
          <input
            disabled={true}
            title="Enhet ändras i artikelregister"
            className="form-control rounded-0 text-end"
            {...props.register(`${props.arrayName}.${index}.unit` as any)}
          />
        );
      },
    }),
    columnHelper.accessor("unitPrice", {
      header: () => <span>Pris per enhet</span>,
      size: 80,
      cell: ({ row: { index } }) => {
        return (
          <input
            className="form-control rounded-0 text-end"
            {...props.register(`${props.arrayName}.${index}.unitPrice` as any, {
              onChange: () => {
                HandleTotalChange(index);
              },
            })}
          />
        );
      },
    }),
    columnHelper.accessor("discountPercentage", {
      header: () => <span>Rabatt %</span>,
      size: 50,
      cell: ({ row: { index } }) => {
        return (
          <input
            className="form-control rounded-0 text-end"
            {...props.register(
              `${props.arrayName}.${index}.discountPercentage` as any,
              {
                onChange: () => {
                  HandleTotalChange(index);
                },
              }
            )}
          />
        );
      },
    }),
    columnHelper.accessor("amount", {
      header: () => <span>Total</span>,
      size: 100,
      cell: ({ row: { index } }) => {
        return (
          <input
            disabled={true}
            className="form-control rounded-0 text-end"
            {...props.register(`${props.arrayName}.${index}.amount` as any)}
          />
        );
      },
    }),
    columnHelper.accessor("vatPercent", {
      header: () => <span>Moms %</span>,
      size: 100,
      cell: ({ row: { index } }) => {
        return (
          <select
            className="form-control rounded-0"
            {...props.register(
              `${props.arrayName}.${index}.vatPercent` as any,
              {
                onChange: () => {
                  HandleTotalChange(index);
                },
              }
            )}
          >
            <option value="25">25</option>
            {/* <option value="0">0</option>
            <option value="6">6</option>
            <option value="12">12</option> */}
          </select>
        );
      },
    }),
    columnHelper.display({
      header: () => <span> </span>,
      id: "actions",
      cell: ({ row: { index } }) => {
        return (
          <div>
            <Button
              size="sm"
              variant="danger"
              onClick={() => {
                RemoveRow(index);
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
            <br />
            <Button
              size="sm"
              variant="success"
              className="mt-1"
              onClick={() => {
                InsertRow(index);
              }}
            >
              <FontAwesomeIcon icon={faAdd} />
            </Button>
          </div>
        );
      },
      size: 10,
    }),
  ];
  const table = useReactTable({
    data,
    columns,
    defaultColumn,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
    enableColumnResizing: true,
  });

  const SelectArticle = (article: Article, rowIndex: number) => {
    props.setValue(
      `${props.arrayName}.${rowIndex}.articleId` as "rows.0.articleId",
      article.articleNumber
    );
    props.setValue(
      `${props.arrayName}.${rowIndex}.text` as "rows.0.text",
      article.description
    );
    if (article.articleNumber) {
      SetRowPrice(rowIndex, article.articleNumber);
    }
    if (article.unit) {
      props.setValue(
        `${props.arrayName}.${rowIndex}.unit` as "rows.0.unit",
        article.unit
      );
    }
  };

  const SetRowPrice = (rowIndex: number, articleId: string) => {
    var priceList = props.getValues("priceList");
    if (priceList) {
      PriceService.getApiV1Price(articleId, priceList).then((response) => {
        props.setValue(
          `${props.arrayName}.${rowIndex}.unitPrice` as "rows.0.unitPrice",
          response.amount
        );
        HandleTotalChange(rowIndex);
        props.getValues(
          `${props.arrayName}.${rowIndex}.unitPrice` as "rows.0.unitPrice"
        );
      });
    }
  };

  const AddRow = () => {
    if (data) {
      var rows = props.getValues("rows");
      var newrows = rows
        ? (rows?.concat(CreateNewRow()) as OrderRow[])
        : [CreateNewRow()];
      setData(newrows);
      props.setValue("rows", newrows);
    }
  };

  const InsertRow = (atIndex: number) => {
    var newrows = props.getValues("rows");
    console.log("InsertRow", atIndex, newrows);
    if (newrows) {
      newrows.splice(atIndex, 0, CreateNewRow());
      console.log("newdata", newrows);
      setData(newrows);
      props.setValue("rows", newrows);
    }
  };

  const RemoveRow = (rowNr: number) => {
    var rows = props.getValues("rows");
    if (rows) {
      let newdata = rows.filter((_, i) => i !== rowNr);
      setData(newdata);
      props.setValue("rows", newdata);
    }
  };

  const HandleQuantityChange = (rowNr: number, value: string) => {
    if (isNaN(Number(value))) {
      value = "0";
    }
    //parse to number
    let nume = Number(value);
    props.setValue(
      `${props.arrayName}.${rowNr}.deliveredQuantity` as "rows.0.deliveredQuantity",
      nume
    );
    HandleTotalChange(rowNr);
  };

  const HandleTotalChange = (rowNr: number) => {
    var quantity = props.getValues(
      `${props.arrayName}.${rowNr}.deliveredQuantity` as "rows.0.deliveredQuantity"
    );
    var unitPrice = props.getValues(
      `${props.arrayName}.${rowNr}.unitPrice` as "rows.0.unitPrice"
    );
    var discountPercentage = props.getValues(
      `${props.arrayName}.${rowNr}.discountPercentage` as "rows.0.discountPercentage"
    );
    var amount =
      (quantity ?? 0) *
      (unitPrice ?? 0) *
      (1 - (discountPercentage ?? 0) / 100);
    amount = Math.round(amount * 100) / 100;
    props.setValue(
      `${props.arrayName}.${rowNr}.amount` as "rows.0.amount",
      amount
    );
    var total = GetOrderTotal();
    props.setValue("amountVatExcluded", total);
    var vatTotal = GetVatTotal();
    props.setValue("totalVAT", vatTotal);
  };

  const GetOrderTotal = () => {
    var val = 0;
    var rows = props.getValues("rows");
    if (rows != null) {
      for (var i = 0; i < rows.length; i++) {
        props.getValues(`${props.arrayName}.${i}.amount` as "rows.0.amount");
        val += rows[i].amount ?? 0;
      }
    }
    return Math.round(val * 100) / 100;
  };

  const GetVatTotal = () => {
    var val = 0;
    var rows = props.getValues("rows");
    if (rows != null) {
      for (var i = 0; i < rows.length; i++) {
        var row = rows[i];
        var amount = row.amount ?? 0;
        var vatPercent = row.vatPercent ?? 0;
        val += (amount * vatPercent) / 100;
      }
    }
    return Math.round(val * 100) / 100;
  };

  return (
    <div>
      <BootstrapTable>
        <thead>
          {table?.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{
                      width: header.getSize(),
                      maxWidth: header.getSize(),
                      minWidth: header.getSize(),
                    }}
                  >
                    {header.isPlaceholder ? null : (
                      <div>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
          <tr>
            <td
              colSpan={columns.length - 3}
              className="text-center align-middle"
            >
              <Button
                size="sm"
                variant="success"
                onClick={() => {
                  AddRow();
                }}
              >
                Ny rad
              </Button>
            </td>
            <td>
              <input
                className="form-control rounded-0 text-end"
                disabled
                defaultValue={props.getValues("amountVatExcluded") ?? 0}
                {...props.register("amountVatExcluded")}
              />
            </td>
            <td>
              <input
                className="form-control rounded-0 text-end"
                disabled
                {...props.register("totalVAT")}
              />
            </td>
            <td></td>
          </tr>
        </tbody>
      </BootstrapTable>
    </div>
  );
};

const CreateNewRow = () => {
  return {
    articleId: "",
    text: "",
    quantity: null,
    deliveredQuantity: null,
    unitPrice: null,
    discountPercentage: null,
    amount: null,
    lineNumber: 0,
  } as OrderRow;
};
