import { Contact } from "../services/openapi";

interface ContactDetailsProps {
  title?: string;
  contact?: Contact;
}

export const ContactDetails = (props: ContactDetailsProps) => {
  return (
    // <Card>
    //   {!!props.title && <Card.Header>{props.title}</Card.Header>}
    //   <Card.Body>
    //     <Row>
    //       <Col>
    <>
      <p>👤 {props.contact?.name}</p>
      <p>📧 {props.contact?.email}</p>
      <p>📞 {props.contact?.phone}</p>
    </>
    //       </Col>
    //     </Row>
    //   </Card.Body>
    // </Card>
  );
};
