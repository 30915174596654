// import { useFetch } from "../Utils/useFetch";

import { Order } from "../../services/openapi";
import { object, SchemaOf, string, number, boolean, array } from "yup";
import { OrderRowValidationSchema } from "./OrderRowValidationSchema";
import { OrderExtensionPropertiesSchema } from "./OrderExtensionsValidationSchema";
// import { CustomerList, Order } from "../typescript-fetch-client-generated";

export const OrderValidationSchema: SchemaOf<Order> = object()
  .shape({
    id: string(),
    documentNumber: string(),
    customerId: string().required("Kund ID är obligatoriskt"),
    customerName: string().nullable(),
    totalVAT: number().nullable(),
    amount: number().nullable(),
    currencyCode: string().required("Valuta är obligatoriskt"),
    euThirdParty: boolean().nullable(),
    priceList: string().nullable(),
    orderDate: string().required("Orderdatum är obligatoriskt"),
    deliveryDate: string().nullable(),
    yourReference: string().nullable(),
    reverseChargeOnConstructionServices: boolean().nullable(),
    rotReducedInvoicingType: number().nullable(),
    rows: array().of(OrderRowValidationSchema).nullable(),
    extensionProperties: OrderExtensionPropertiesSchema,
    comments: string().nullable(),
    grossWeightKg: number().nullable(),
  })
  .optional();
