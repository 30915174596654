import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { OrderRow } from "../services/openapi";
import { Table } from "react-bootstrap";

const columnHelper = createColumnHelper<OrderRow>();

const columns = [
  columnHelper.accessor("articleId", {
    header: () => <span>Artikelnummer</span>,
  }),
  columnHelper.accessor("text", {
    header: () => <span>Beskrivning</span>,
  }),
  columnHelper.accessor("quantity", {
    header: () => <span>Antal</span>,
  }),
  columnHelper.accessor("deliveredQuantity", {
    header: () => <span>Lev. antal</span>,
  }),
  columnHelper.accessor("unitPrice", {
    header: () => <span>Pris/enhet</span>,
  }),
  columnHelper.accessor("discountPercentage", {
    header: () => <span>Rabatt %</span>,
  }),
  columnHelper.accessor("amount", {
    header: () => <span>Total</span>,
  }),
];

export interface InvoiceRowTableProps {
  data: OrderRow[];
}

export const InvoiceRowTable = (props: InvoiceRowTableProps) => {
  let data = props.data;
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Table variant="bordered bg-white ">
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th key={header.id}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell?.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
