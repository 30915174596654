import { Container } from "react-bootstrap";
import CustomersList from "./CustomersList";

function Customers() {
  return (
    <Container fluid="lg">
      <h1>Kunder</h1>
      <CustomersList />
    </Container>
  );
}

export default Customers;
