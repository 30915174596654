import { Row, Col, Image, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileInvoiceDollar,
  faPaperPlane,
  faPeopleGroup,
} from "@fortawesome/free-solid-svg-icons";


export default function Home() {
  return (
    <>
      <div className="container-md">
        <div className="py-3 py-sm-5">
          <Row className="justify-content-center">
            <Col sm="8" className="align-self-center">
              <Row className="pb-xs-5 pb-md-0">
                <Col className="align-self-center">
                  <div className="py-3 d-flex flex-row align-items-center">
                    <FontAwesomeIcon
                      className="pe-4"
                      icon={faFileInvoiceDollar}
                      size="4x"
                      color=" var(--primary)"
                      fixedWidth
                    />
                    <h3>Åtkomst överallt</h3>
                  </div>
                  <div className="py-3 d-flex flex-row align-items-center">
                    <FontAwesomeIcon
                      className="pe-4"
                      icon={faPaperPlane}
                      size="4x"
                      color=" var(--primary)"
                      fixedWidth
                    />
                    <h3>Skapa och skicka fakturor</h3>
                  </div>
                  <div className="py-3 d-flex flex-row align-items-center">
                    <FontAwesomeIcon
                      className="pe-4"
                      icon={faPeopleGroup}
                      size="4x"
                      color=" var(--primary)"
                      fixedWidth
                    />
                    <h3>Håll ordning på kontakter och kunder</h3>
                  </div>
                </Col>
              </Row>
            </Col>
            {/* <Col
              md="4"
              xs="4"
              className="pt-5 py-sm-0 d-flex align-items-center"
            >
              <img
                alt="..."
                src="https://tidrappisol.blob.core.windows.net/shared/Asset 11.svg"
              />
            </Col> */}
          </Row>
        </div>
        <Row className="py-5">
          <Col sm="6">
            <Card color="light">
              <Card.Header>
                <Card.Title className="text-center">
                  Skapa order och fakturor
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col xs="4">
                    <img
                      alt="logo"
                      width="100%"
                      className="img-responsive fit-image"
                      src="https://easypractice.net/wp-content/uploads/2020/11/Logo-Visma-eAccounting.png"
                    />
                  </Col>
                  <Col xs="4" className="align-self-center">
                    <img
                      alt="logo"
                      width="100%"
                      className="img-responsive fit-image"
                      src="https://cdn2.downdetector.com/static/uploads/logo/fortnox.png"
                    />
                  </Col>
                  <Col xs="4" className="align-self-center">
                    <img
                      alt="logo"
                      width="100%"
                      className="img-responsive fit-image"
                      src="https://easypractice.net/wp-content/uploads/2021/08/bokio.png"
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col sm="6" className="mt-4 mt-sm-0">
            {" "}
            <Card color="light">
              <Card.Header>
                <Card.Title className="text-center">
                  Integrera med CRM
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Row className="justify-content-center">
                  <Col xs="4">
                    <Image
                      width="100%"
                      className="img-responsive fit-image"
                      src="https://scontent-arn2-1.xx.fbcdn.net/v/t1.6435-9/101216339_100284411710988_1495899236175183872_n.png?_nc_cat=103&ccb=1-7&_nc_sid=85a577&_nc_ohc=1pQanTPf2RIAX-9ASth&_nc_ht=scontent-arn2-1.xx&oh=00_AT8pgU6_GNfGlT3Tj2BCj7_eWyqOeaik35SpEib6K65irQ&oe=62B1E569"
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      {/* <Container fluid className="py-5 bg-light">
        <Container className="container-md pb-5">
          <Row className="py-5 d-flex justify-content-center">
            <Col xl="8" className="order-last order-xl-first">
              <h1 className="">Ett bättre sätt att sälja</h1>
              <p>
                Sella är ett bättre sätt att sälja. Vi har samlat de mest
                önskade funktionerna i ett enkel tjänst som du kan börja använda
                direkt, utan att behöva ändra i dina nuvarande system. Så här
                går det till att bli kund hos oss:
              </p>
              <h4>
                <ol>
                  <li className="py-1">Du får ditt login</li>
                  <li className="py-1">Du skapar konton åt dina medarbetare</li>
                  <li className="py-1">
                    Sella kopplar till ditt bokföringssystem
                  </li>
                  <li className="py-1">Sella kopplar till ditt CRM-system</li>
                  <li className="py-1">Börja sälja </li>
                </ol>
              </h4>
            </Col>
            <Col className="pb-4 pb-xl-0 d-flex align-items-center justify-content-center order-sm-first">
              <FontAwesomeIcon
                className="ps-md-4"
                icon={faHandshake}
                size="10x"
                color=" var(--primary)"
                fixedWidth
              />
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className="py-5">
        <Row className="d-flex justify-content-center">
          <Col md="6">
            <Image
              src="https://www.pcquest.com/wp-content/uploads/2020/06/6-things-people-should-take-care-of-while-selling-their-old-phones.jpg"
              rounded
              width="100%"
            />
          </Col>
          <Col md="6" className="pt-3 pt-md-0">
            <Image
              src="https://tidrappisol.blob.core.windows.net/shared/pexels-andrea-piacquadio-926390.jpg"
              rounded
              width="100%"
            />
          </Col>
        </Row>
      </Container> */}
    </>
  );
}
