/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Customer } from '../models/Customer';
import type { CustomerList } from '../models/CustomerList';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomersService {

    /**
     * @param page 
     * @param pageSize 
     * @param searchterm 
     * @returns CustomerList Success
     * @throws ApiError
     */
    public static getApiV1Customers(
page: number = 1,
pageSize: number = 30,
searchterm: string = '',
): CancelablePromise<CustomerList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Customers',
            query: {
                'page': page,
                'pageSize': pageSize,
                'searchterm': searchterm,
            },
        });
    }

    /**
     * @param id 
     * @returns Customer Success
     * @throws ApiError
     */
    public static getApiV1Customers1(
id: string,
): CancelablePromise<Customer> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Customers/{id}',
            path: {
                'id': id,
            },
        });
    }

}
