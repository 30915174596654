import React from "react";
import { useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import Typeahead from "react-bootstrap-typeahead/types/core/Typeahead";
import { Article, ArticlesService } from "../services/openapi";

interface ArticleSearchInputProps {
  onArticleSelected: (article: Article) => void;
  id: string;
  selectedArticleId: string | undefined | null;
}

export function ArticleSearchInput(props: ArticleSearchInputProps) {
  const [isLoadingArticle, setIsLoadingArticle] = useState(false);
  const [articles, setArticles] = useState<Article[]>([]);
  const typeaheadref = React.createRef<Typeahead>();
  const filterBy = () => true;
  const handleSearchArticles = (query: string) => {
    setIsLoadingArticle(true);
    ArticlesService.getApiV1Articles(1, 5, query).then((result) => {
      if (result.data) {
        setArticles(result.data);
      } else {
        setArticles([]);
      }
      setIsLoadingArticle(false);
    });
  };

  // const _handleBlur = () => {
  //   // Check if there are selections.
  //   if (props.selectedArticleId) {
  //     // Clear the component if not.
  //     _typeahead.getInstance().clear();
  //   }
  // };

  return (
    <AsyncTypeahead
      id={props.id}
      options={articles}
      ref={typeaheadref}
      isLoading={isLoadingArticle}
      onSearch={handleSearchArticles}
      filterBy={filterBy}
      labelKey={"articleNumber"}
      defaultSelected={props.selectedArticleId ? [props.selectedArticleId] : []}
      onBlur={() => {
        if (!typeaheadref.current?.state.selected.length) {
          // Clear the component if not.
          typeaheadref.current?.clear();
        }
      }}
      onChange={(selected) => {
        if (selected.length > 0) {
          props.onArticleSelected(selected[0] as Article);
        } else {
          props.onArticleSelected({} as Article);
        }
      }}
      placeholder="Sök artikel..."
      searchText="Söker..."
      emptyLabel="Inga träffar"
    />
  );
}

export default ArticleSearchInput;
