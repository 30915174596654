import { useEffect, useState } from "react";
import { Button, ListGroup, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import {
  OrderFilterOption,
  OrderList,
  OrderMetaOptionsListMeta,
  OrderSortingOption,
  OrdersService,
} from "./../../services/openapi";
import PaginationList from "../PaginationList";
import { OrderListItem } from "./OrderListItem";
import SearchBar from "../SearchBar";
import { OrderSortDropdown } from "./OrderSortDropdown";
import { Link } from "react-router-dom";
import OrderFilterRow from "./OrderFilterRow";
// import { OrderFilterDropdown } from "./OrderFilterDropdown";

function OrdersList(props: { customerId?: string }) {
  const defaultOrderList: OrderList = {
    data: null,
    meta: {
      currentPage: 1,
      searchTerm: null,
      pageSize: 30,
      options: {
        filterOption: OrderFilterOption.ALL,
        sortOption: OrderSortingOption.DOCUMENT_NUMBER,
        sortDescending: true,
        customerId: props.customerId ?? "",
      },
    } as OrderMetaOptionsListMeta,
  };
  const [data, setData] = useState<OrderList>(defaultOrderList);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAdvancedSearch, setIsAdvancedSearch] = useState<boolean>(false);

  useEffect(() => {
    if (!isLoading) {
      setIsLoading(true);
      try {
        OrdersService.getApiV1Orders(
          data.meta.currentPage,
          data.meta.pageSize,
          data?.meta.options.customerId ?? "",
          data?.meta?.searchTerm ?? "",
          data?.meta.options.sortOption,
          data?.meta.options.sortDescending,
          data?.meta.options.filterOption,
          data?.meta.options.machineNumberSearch ?? ""
        ).then((response) => {
          setData(response);
          setIsLoading(false);
        });
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    }
  }, [
    props.customerId,
    data?.meta.options.sortOption,
    data?.meta.options.sortDescending,
    data?.meta.options.filterOption,
    data?.meta.currentPage,
    data?.meta.pageSize,
    data?.meta.searchTerm,
    data?.meta.options.machineNumberSearch,
  ]);

  const handleChangePage = (page: number) => {
    setData({
      ...data,
      meta: {
        ...data.meta,
        currentPage: page,
        // options: {
        //   ...data.meta.options,
        // },
      },
    });
  };

  const handleSortChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (data && data.meta) {
      setData({
        ...data,
        meta: {
          ...data.meta,
          options: {
            ...data.meta.options,
            sortOption: event.currentTarget.value as OrderSortingOption,
            sortDescending: !data.meta.options.sortDescending,
          },
        },
      });
    }
  };

  const handleFilterChange = (filter: OrderFilterOption) => {
    if (data && data.meta) {
      setData({
        ...data,
        meta: {
          ...data.meta,
          currentPage: 1,
          options: {
            ...data.meta.options,
            filterOption: filter,
          },
        },
      });
    }
  };
  const handleSearch = (searchterm: string) => {
    setIsAdvancedSearch(false);
    if (data && data.meta) {
      setData({
        ...data,
        meta: {
          ...data.meta,
          searchTerm: searchterm,
          options: {
            ...data.meta.options,
            machineNumberSearch: undefined,
          },
        },
      });
    }
  };

  const handleAdvancedSearch = (searchterm: string) => {
    setIsAdvancedSearch(true);
    if (data && data.meta) {
      setData({
        ...data,
        meta: {
          ...data.meta,
          options: {
            ...data.meta.options,
            machineNumberSearch: searchterm,
          },
        },
      });
    }
  };

  return (
    <>
      <div className="d-flex">
        <Link to="/orders/new">
          <Button className="me-2" variant="info">
            Skapa
          </Button>
        </Link>
        <SearchBar
          handleSearch={handleSearch}
          handleAdvancedSearch={handleAdvancedSearch}
          isAdvancedSearch={isAdvancedSearch}
        >
          <OrderSortDropdown
            handleSortChange={handleSortChange}
            selectedSort={data.meta.options.sortOption}
          />
        </SearchBar>
      </div>
      <OrderFilterRow
        handleFilterChange={handleFilterChange}
        selectedFilter={data.meta.options.filterOption}
      />
      <ListGroup className="mt-2">
        {data.data == null ? (
          <Spinner
            className="align-self-center mt-3"
            animation="border"
            variant="primary"
          />
        ) : (
          data.data?.map((item) => (
            <OrderListItem
              key={item.documentNumber}
              order={item}
              showCustomer={props.customerId == null}
            />
          ))
        )}
      </ListGroup>
      {data?.data != null && data.meta && (
        <PaginationList
          totalPages={data.meta.totalNumberOfPages || 0}
          currentPage={data.meta.currentPage || 0}
          handleChangePage={handleChangePage}
          totalItems={data.meta.totalNumberOfResults || 0}
        />
      )}
    </>
  );
}

OrdersList.propTypes = {
  customerId: PropTypes.string,
};

export default OrdersList;
