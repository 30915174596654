import { Dropdown, DropdownButton } from "react-bootstrap";
import { OrderDocumentType, OrderPrintService } from "../../services/openapi";

export interface OrderPrintDropdownProps {
  orderId: string;
}

export default function OrderPrintDropdown(props: OrderPrintDropdownProps) {
  const printDeliveryNote = () => {
    OrderPrintService.getApiV1OrderPrint(
      parseInt(props.orderId),
      OrderDocumentType.DELIVERY_NOTE
    ).then((response) => {
      const link = document.createElement("a");
      link.download = props.orderId + "-fraktsedel.pdf";
      link.href = URL.createObjectURL(response);
      link.click();
    });
  };

  return (
    <DropdownButton
      size="sm"
      className="d-inline-block ms-1"
      id="dropdown-basic-button"
      title="Skriv Ut"
      variant="primary"
    >
      <Dropdown.Item onClick={() => printDeliveryNote()}>
        Fraktsedel
      </Dropdown.Item>
    </DropdownButton>
  );
}
