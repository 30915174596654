import { useState } from "react";
import { Button, Col, Modal, Row, Table } from "react-bootstrap";
import { OrdersService } from "../../services/openapi";
import { InvoiceFromOrdersResult } from "../../services/openapi";

export interface IGroupedInvoiceModalProps {
  show: boolean;
  onHide: () => void;
}

export const GroupedInvoiceModal = (props: IGroupedInvoiceModalProps) => {
  const [isLoading, setIsLoading] = useState<boolean | null>(null);
  const [result, setResult] = useState<InvoiceFromOrdersResult[] | null>(null);
  const handleGroupInvoiceDeliveredOrders = () => {
    if (!isLoading) {
      setIsLoading(true);
      OrdersService.getApiV1OrdersInvoiceDelivered()
        .then((response) => {
          setResult(response);
          setIsLoading(false);
        })
        .catch((error) => {
          alert(error);
          setIsLoading(false);
        });
    }
  };

  const handleClearResult = () => {
    setResult(null);
  };

  return (
    <Modal show={props.show} onHide={props.onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Skapa Fakturor</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          {result == null ? (
            <Col className="text-center">
              <p>
                Här kan du skapa samlingsfakturor av alla dina levererade ordrar
              </p>
              <Button
                variant="primary"
                onClick={handleGroupInvoiceDeliveredOrders}
                disabled={isLoading === true}
              >
                {isLoading ? "Skapar fakturor..." : "Skapa Fakturor"}
              </Button>
            </Col>
          ) : (
            <Col className="text-center">
              {result.length > 0 ? (
                <div>
                  <p>Fakturorna har skapats:</p>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Kund</th>
                        <th>Från ordernummer</th>
                        <th>Fakturanummer</th>
                      </tr>
                    </thead>
                    <tbody>
                      {result.map((r) => (
                        <InvoiceResultRow key={r.customerId} result={r} />
                      ))}
                    </tbody>
                  </Table>
                  <Button variant="success" onClick={handleClearResult}>
                    Klar
                  </Button>
                </div>
              ) : (
                <p>
                  Inga fakturor skapades.
                  <br />
                  (Du kanske inte har några levererade ordrar?)
                </p>
              )}
            </Col>
          )}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Stäng</Button>
      </Modal.Footer>
    </Modal>
  );
};

const InvoiceResultRow = (props: { result: InvoiceFromOrdersResult }) => {
  return (
    <tr className={!props.result.isSuccess ? "table-danger" : ""}>
      <td>
        {props.result.customerName} (Kundnr: {props.result.customerId})
      </td>
      <td className="text-break">
        {props.result.orderIds?.map((id) => (
          <span key={id}>
            {id}
            <br />
          </span>
        ))}
      </td>
      <td className="text-break">{props.result.message}</td>
    </tr>
  );
};
