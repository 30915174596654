// import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { AppVariables } from "../../services/openapi";

interface OrderFieldsProps {
  appvariables: AppVariables;
  setAppVariables: (newVars: AppVariables) => void;
}

const OrderFields = (props: OrderFieldsProps) => {
  const { register, watch } = useForm();
  let extensionprops = props.appvariables.extensionSettingsOrder
    ? props.appvariables.extensionSettingsOrder
    : {};
  return (
    <Form>
      <Form.Group className="mb-2 mt-3">
        <Form.Check
          type="switch"
          label="Leveransdatum som period"
          checked={watch("deliverydateasperiod")}
          {...register("deliverydateasperiod")}
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Check
          type="switch"
          label="Ange leveranstid"
          checked={extensionprops.showDeliveryTimesAsRange}
          onChange={(e) => {
            extensionprops.showDeliveryTimesAsRange = e.target.checked;
            props.setAppVariables(props.appvariables);
          }}
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Check
          type="switch"
          label="Anvisningar leverans"
          checked={extensionprops.showDeliveryInstructions}
          onChange={(e) => {
            extensionprops.showDeliveryInstructions = e.target.checked;
            props.setAppVariables(props.appvariables);
          }}
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Check
          type="switch"
          label="Upphämtningsadress"
          checked={extensionprops.showPickupAddress}
          onChange={(e) => {
            extensionprops.showPickupAddress = e.target.checked;
            props.setAppVariables(props.appvariables);
          }}
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Check
          type="switch"
          label="Anvisningar upphämtning"
          checked={extensionprops.showPickupInstructions}
          onChange={(e) => {
            extensionprops.showPickupInstructions = e.target.checked;
            props.setAppVariables(props.appvariables);
          }}
        />
      </Form.Group>
      {/* <Form.Group className="mb-2">
        <Form.Check
          type="switch"
          label="Kontaktuppgifter upphämtning"
          checked={extensionprops.}
          onChange={(e) => {
            extensionprops.showPickupContact = e.target.checked;
            props.setAppVariables(props.appvariables);
          }}
        />
      </Form.Group> */}
    </Form>
  );
};

export default OrderFields;
