import { ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Order } from "./../../services/openapi";

interface OrdersListItemProps {
  order: Order;
  showCustomer?: boolean;
}

export const OrderListItem = (props: OrdersListItemProps) => (
  <ListGroup.Item
    key={props.order.documentNumber}
    as={Link}
    to={"/orders/details/" + props.order.documentNumber}
    className="d-flex justify-content-between align-items-center"
    action
  >
    <div>
      <h5 className="mb-0">
        {props.order.documentNumber}
        {props.showCustomer && " - " + props.order.customerName}
        {props.showCustomer && (
          <span className="text-muted small"> {props.order.customerId}</span>
        )}
      </h5>
      <span>{props.order.orderDate} </span>
    </div>
    <div>
      <span>
        {props.order.amountVatExcluded} {props.order.currencyCode}
      </span>
    </div>
  </ListGroup.Item>
);
