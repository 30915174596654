import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
import { Container, Spinner, Row, Col, Card, Form } from "react-bootstrap";
import { Invoice, InvoiceService } from "../../services/openapi";
import { InvoiceRowTable } from "../InvoiceRowTable";

function InvoicesDetails() {
  const params = useParams();

  const [data, setData] = useState<Invoice>();

  useEffect(() => {
    if (params.id) {
      InvoiceService.getApiV1Invoice1(params.id).then((response: Invoice) => {
        setData(response);
      });
    }
  }, [params.id]);

  return (
    <Container fluid="lg">
      <h1>Fakturadetaljer</h1>
      {data && data.documentNumber ? (
        <>
          <hr />
          <Row className="mb-2">
            <Card className="mb-3">
              <Card.Body>
                <Row>
                  <Col>
                    <Form.Check
                      label="Bokförd"
                      id={`disabled-default-check1`}
                      readOnly
                      checked={data.booked === true}
                    />
                    <Form.Check
                      label="Skickad"
                      id={`disabled-default-check2`}
                      readOnly
                      checked={data.sent === true}
                    />
                    <Form.Check
                      label="Betald"
                      id={`disabled-default-check34`}
                      readOnly
                      checked={data.paid === true}
                    />
                    <Form.Check
                      label="Makulerad"
                      id={`disabled-default-check4`}
                      readOnly
                      checked={data.cancelled === true}
                    />
                    <Form.Check
                      label="Kreditfaktura"
                      id={`disabled-default-check5`}
                    >
                      <Form.Check.Input
                        type="checkbox"
                        readOnly
                        checked={!!data.credit}
                      />
                      <Form.Check.Label>Kreditfaktura </Form.Check.Label>
                      {data.creditInvoiceReference && (
                        <Link
                          className="ms-1"
                          to={
                            "/invoices/details/" + data.creditInvoiceReference
                          }
                        >
                          {data.creditInvoiceReference}
                        </Link>
                      )}
                    </Form.Check>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col>
                    <h4>Allmänt</h4>
                    <p>Fakturanummer: {data.documentNumber}</p>
                    <p>Fakturadatum: {data.invoiceDate}</p>
                    <p>Förfallodatum: {data.dueDate}</p>
                    <p>Fakturatext: Missinginmodel</p>
                  </Col>
                  <Col>
                    <h4>Fakturauppgifter</h4>
                    <p>Vår referens: {data.ourReference}</p>
                    <p>Er referens: {data.yourReference}</p>
                    <p>Ert ordernummer: {data.yourOrderNumber}</p>
                    <p>Er referens: {data.yourReference}</p>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col>
                    <h4>Kunduppgifter</h4>
                    <p>Kundens namn: {data.customerName}</p>
                    <p>Organisationsnummer: {data.organisationNumber}</p>
                    <p>Fakturaadress: {data.address1}</p>
                    <p>Fakturaadress 2: {data.address2}</p>
                    <p>Postnummer: {data.zipCode}</p>
                    <p>Ort: {data.city}</p>
                    <p>Land: {data.country}</p>
                  </Col>
                  <Col>
                    <h4>Leveransuppgifter</h4>
                    <p>Leveransdatum: {data.deliveryDate}</p>
                    <p>Leveransadress: {data.deliveryAddress1}</p>
                    <p>Leveransadress 2: {data.deliveryAddress2}</p>
                    <p>Postnr: {data.zipCode}</p>
                    <p>Ort: {data.city}</p>
                    <p>Land: {data.country}</p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Row>
          <Row>
            <Col>{!!data.rows && <InvoiceRowTable data={data.rows} />}</Col>
          </Row>
          <Row>
            <Row>
              <Col className="text-end">
                <h4 className="fw-light">
                  Total Exl. Moms: {data.gross} {data.currencyCode}
                </h4>
              </Col>
            </Row>
            <Row>
              <Col className="text-end">
                <h4 className="fw-light">
                  Moms: {data.totalVAT} {data.currencyCode}
                </h4>
              </Col>
            </Row>
            <Row>
              <Col className="text-end fw-light">
                <h3>
                  <strong>Total inkl. moms: </strong>
                  {data.amount} {data.currencyCode}
                </h3>
              </Col>
            </Row>
          </Row>
        </>
      ) : (
        <Row className="d-flex justify-content-center mt-4">
          <Spinner animation="border" variant="primary" />
        </Row>
      )}
    </Container>
  );
}

InvoicesDetails.propTypes = {
  id: PropTypes.string,
};

export default InvoicesDetails;
