import { object, SchemaOf, string, boolean } from "yup";
import { ExtensionPropertiesOrderRow } from "../../services/openapi";

export const OrderRowExtensionsValidationSchema: SchemaOf<ExtensionPropertiesOrderRow> =
  object()
    .shape({
      id: string(),
      orderRowId: string(),
      // orderRow: mixed(),
      externalOrderRowId: string().nullable(),
      isDelivered: boolean().nullable(),
      deliveryAfterDate: string().nullable(),
      deliveryBeforeDate: string().nullable(),
      deliveryAfterTime: string().nullable(),
      deliveryBeforeTime: string().nullable(),
      deliveryInstructions: string().nullable(),
      pickUpAfterDate: string().nullable(),
    })
    .optional();
