import { Provider } from "../services/openapi";

interface SoftwareIconProps {
  Provider: Provider;
}

const SoftwareIcon = (props: SoftwareIconProps) => {
  //list all the software providers from the provider enum
  return (
    <img
      onClick={() =>
        (location.href = props.Provider.authUri ? props.Provider.authUri : "")
      }
      alt="logo"
      width="100%"
      className="img-responsive fit-image"
      role="button"
      src={props.Provider.imageUri ? props.Provider.imageUri : ""}
    />
  );
};

export default SoftwareIcon;
