/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Contact } from '../models/Contact';
import type { ContactList } from '../models/ContactList';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ContactsService {

    /**
     * @param page 
     * @param pageSize 
     * @param customerId 
     * @param searchTerm 
     * @returns ContactList Success
     * @throws ApiError
     */
    public static getApiV1Contacts(
page: number = 1,
pageSize: number = 30,
customerId: string = '',
searchTerm: string = '',
): CancelablePromise<ContactList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Contacts',
            query: {
                'page': page,
                'pageSize': pageSize,
                'customerId': customerId,
                'searchTerm': searchTerm,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Contact Success
     * @throws ApiError
     */
    public static putApiV1Contacts(
requestBody?: Contact,
): CancelablePromise<Contact> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/Contacts',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns Contact Success
     * @throws ApiError
     */
    public static postApiV1Contacts(
requestBody?: Contact,
): CancelablePromise<Contact> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Contacts',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns Contact Success
     * @throws ApiError
     */
    public static getApiV1Contacts1(
id: string,
): CancelablePromise<Contact> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Contacts/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @returns boolean Success
     * @throws ApiError
     */
    public static deleteApiV1Contacts(
id: string,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/Contacts/{id}',
            path: {
                'id': id,
            },
        });
    }

}
