import { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { OrderDocumentType, OrderPrintService } from "../../services/openapi";

interface OrderEmailButtonProps {
  orderId: string;
}

export const OrderEmailButton = (props: OrderEmailButtonProps) => {
  const { orderId } = props;

  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const [comment, setComment] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const HandleSendEmail = (email: string, comment: string) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    OrderPrintService.getEmail(
      Number(orderId),
      OrderDocumentType.DELIVERY_NOTE,
      email,
      comment
    )
      .then(() => {
        alert("Email skickat");
        setIsSubmitting(false);
        setShow(false);
      })
      .catch(() => {
        alert("Email kunde inte skickas");
        setIsSubmitting(false);
      });
  };
  return (
    <>
      <Button
        onClick={() => setShow(true)}
        variant="primary"
        size="sm"
        style={{ whiteSpace: "nowrap" }}
        className="ms-1"
      >
        Epost - Skicka Fraktsedel
      </Button>

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Skicka Fraktsedel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Epost
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder=""
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="comment" className="form-label">
              Kommentar
            </label>
            <textarea
              className="form-control"
              id="comment"
              rows={3}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Stäng
          </Button>
          <Button
            variant="primary"
            onClick={() => HandleSendEmail(email, comment)}
            disabled={isSubmitting}
          >
            Skicka
            <Spinner
              animation="border"
              variant="light"
              size="sm"
              className={isSubmitting ? "ms-2" : "d-none"}
            />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OrderEmailButton;
