import { Container, Row, Col } from "react-bootstrap";
import OrdersList from "./OrdersList";

function Orders() {
  return (
    <Container fluid="lg">
      <Row>
        <Col>
          <h1>Ordrar</h1>
        </Col>
      </Row>
      <OrdersList />
    </Container>
  );
}

export default Orders;
