/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum InvoiceFilterOption {
    ALL = 'All',
    CANCELLED = 'Cancelled',
    FULLY_PAID = 'FullyPaid',
    UN_PAID = 'UnPaid',
    UN_PAID_AND_OVERDUE = 'UnPaidAndOverdue',
    UN_BOOKED = 'UnBooked',
}
