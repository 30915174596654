import { faArrowsUpDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Form } from "react-bootstrap";
import { OrderSortingOption } from "../../services/openapi";

interface OrderSortDropdownProps {
  handleSortChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedSort?: OrderSortingOption;
}

export const OrderSortDropdown = (props: OrderSortDropdownProps) => (
  <Dropdown>
    <Dropdown.Toggle
      color="secondary"
      className="dropdown-toggle-no-arrow dropdown-toggle-form-prepend btn-light border"
    >
      <FontAwesomeIcon icon={faArrowsUpDown} />
    </Dropdown.Toggle>
    <Dropdown.Menu>
      {Object.values(OrderSortingOption).map((orderKey) => (
        <Dropdown.Item as="div" key={orderKey}>
          <Form className="d-flex">
            <Form.Check
              id={orderKey}
              type="checkbox"
              value={orderKey}
              checked={props.selectedSort === orderKey}
              onChange={props.handleSortChange}
            />
            <Form.Check.Label className="ms-2" htmlFor={orderKey}>
              {orderKey}
            </Form.Check.Label>
          </Form>
        </Dropdown.Item>
      ))}
    </Dropdown.Menu>
  </Dropdown>
);
