import { Badge, Col, Row } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";

interface PaginationListProps {
  currentPage: number;
  totalPages: number;
  handleChangePage: (page: number) => void;
  totalItems: number;
}

export default function PaginationList(props: PaginationListProps) {
  let links = [];
  let currentPage = props.currentPage;
  let displayMax = 3;
  if (currentPage !== 1 && props.totalPages > 1) {
    links.push(
      <Pagination.Item
        key="first"
        onClick={() => {
          {
            props.handleChangePage(1);
            document.body.scrollTop = 0;
          }
        }}
      >
        {"<<"}
      </Pagination.Item>
    );
    links.push(
      <Pagination.Item
        key="previous"
        onClick={() => {
          {
            props.handleChangePage(currentPage - 1);
            document.body.scrollTop = 0;
          }
        }}
      >
        {"<"}
      </Pagination.Item>
    );
  }
  //the first page to be listed is the current page minus the displayMax/2 rounded down, but not less than 1
  let firstPage = Math.max(Math.floor(currentPage - displayMax / 2), 1);
  for (
    let i = firstPage;
    i <= props.totalPages && i <= currentPage + displayMax;
    i++
  ) {
    if (props.totalItems > 0) {
      links.push(
        <Pagination.Item
          key={i}
          className={currentPage === i ? "active" : ""}
          value={i}
          onClick={() => {
            props.handleChangePage(i);
            document.body.scrollTop = 0;
          }}
        >
          {i}
        </Pagination.Item>
      );
    }
  }

  if (currentPage !== props.totalPages && props.totalPages > 1) {
    links.push(
      <Pagination.Item
        key="next"
        onClick={() => {
          props.handleChangePage(currentPage + 1);
          document.body.scrollTop = 0;
        }}
      >
        {">"}
      </Pagination.Item>
    );
    links.push(
      <Pagination.Item
        key="last"
        onClick={() => {
          props.handleChangePage(props.totalPages);
          document.body.scrollTop = 0;
        }}
      >
        {">>"}
      </Pagination.Item>
    );
  }
  return (
    <>
      {props.totalItems > 5 && (
        <>
          <Pagination
            className="mt-3 justify-content-center"
            aria-label="Page navigation"
          >
            {links}
          </Pagination>
          <Row>
            <Col className="d-flex justify-content-center">
              <Badge color="light">
                <strong>Totalt antal resultat: {props.totalItems}</strong>
              </Badge>
            </Col>
          </Row>
        </>
      )}
      {props.totalItems === 0 && (
        <h5 className="text-center mt-3">Inga resultat att visa</h5>
      )}
    </>
  );
}
