import { Container, Row, Col } from "react-bootstrap";
import InvoicesList from "./InvoicesList";

function Invoices() {
  return (
    <Container fluid="lg">
      <Row>
        <Col>
          <h1>Fakturor</h1>
        </Col>
      </Row>
      <InvoicesList />
    </Container>
  );
}

export default Invoices;
