import {
  InteractionRequiredAuthError,
  SilentRequest,
  PublicClientApplication,
} from "@azure/msal-browser";

export const msalGetToken = async (
  pca: PublicClientApplication
): Promise<string> => {
  //get accounts using msal usaccount hook
  const account = pca.getActiveAccount();
  if (!account) {
    pca.loginRedirect();
    return "";
  }
  const authRequest: SilentRequest = {
    scopes: [
      "https://cabanossyorg.onmicrosoft.com/10ea7d34-0bd1-4cb0-b75a-3ccf49965b64/Sella.Read",
    ],
    account: account,
  };
  //check if interactionstatu is in progress
  try {
    let response = await pca.acquireTokenSilent(authRequest);
    return response.accessToken;
  } catch (e) {
    if (e instanceof InteractionRequiredAuthError) {
      pca.acquireTokenRedirect(authRequest);
    }
  }

  return "";
};
