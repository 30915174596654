/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DataSourceType } from '../models/DataSourceType';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ConnectFortnoxService {

    /**
     * @param code 
     * @param dataSourceType 
     * @returns any Success
     * @throws ApiError
     */
    public static getFortnoxcallback(
code: string,
dataSourceType: DataSourceType,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/fortnoxcallback',
            query: {
                'code': code,
                'dataSourceType': dataSourceType,
            },
        });
    }

}
