/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ArticleList } from '../models/ArticleList';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ArticlesService {

    /**
     * @param page 
     * @param pageSize 
     * @param searchTerm 
     * @returns ArticleList Success
     * @throws ApiError
     */
    public static getApiV1Articles(
page: number = 1,
pageSize: number = 30,
searchTerm: string = '',
): CancelablePromise<ArticleList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Articles',
            query: {
                'page': page,
                'pageSize': pageSize,
                'searchTerm': searchTerm,
            },
        });
    }

}
