// import { useFetch } from "../Utils/useFetch";

import { object, SchemaOf, string, number } from "yup";
import { OrderRow } from "../../services/openapi";
import { OrderRowExtensionsValidationSchema } from "./OrderRowExtensionsValidationSchema";
// import { CustomerList, Order } from "../typescript-fetch-client-generated";

export const OrderRowValidationSchema: SchemaOf<OrderRow> = object({
  id: string().nullable(true),
  lineNumber: number().nullable(),
  deliveredQuantity: number()
    .nullable()
    .transform((_, val) => (val ? Number(val) : null)),
  text: string().nullable(),
  unitPrice: number()
    .nullable()
    .transform((_, val) => (val ? Number(val) : null)),
  vatPercent: number()
    .nullable()
    .transform((_, val) => (val ? Number(val) : null)),
  amount: number()
    .nullable()
    .transform((_, val) => (val ? Number(val) : null)),
  discountPercentage: number()
    .nullable(true)
    .transform((_, val) => (val ? Number(val) : null)),
  articleId: string().nullable(),
  quantity: number()
    .nullable()
    .transform((_, val) => (val ? Number(val) : null)),
  unit: string().nullable(),
  price: number().nullable(),
  extensionProperties: OrderRowExtensionsValidationSchema.nullable(),
});
