/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Provider } from '../models/Provider';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProvidersService {

    /**
     * @returns Provider Success
     * @throws ApiError
     */
    public static getApiV1Providers(): CancelablePromise<Array<Provider>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Providers',
        });
    }

}
