import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import { useMsal } from "@azure/msal-react";

export default function NavMenu() {
  const { instance } = useMsal();
  var request = {
    scopes: [
      "https://cabanossyorg.onmicrosoft.com/fb6c66d0-6291-4a66-a24f-f231fc5b63ad/User.Test",
    ],
    account: instance.getAllAccounts()[0],
  };

  const handleLogin = () => {
    instance.acquireTokenSilent(request).catch((error) => {
      if (error) {
        // fallback to interaction when silent call fails
        return instance.acquireTokenRedirect(request).catch((error) => {
          // Acquire token interactive failure
          console.log(error);
        });
      }
    });
  };

  const handleLogout = () => {
    instance
      .logoutRedirect({ postLogoutRedirectUri: "/" })
      .catch((error) => console.log(error));
  };

  let isLoggedIn = instance.getAllAccounts().length > 0;
  return (
    <>
      <Navbar
        bg="light"
        className="py-2"
        collapseOnSelect
        expand="lg"
        // justify="true"
      >
        <div className="container-md justify-content-between">
          <div>
            <Navbar.Brand as={Link} to="/" href="#home">
              <img
                src="https://tidrappisol.blob.core.windows.net/sella/Asset 3.svg"
                alt="logo"
                style={{ width: 100 }}
              />
            </Navbar.Brand>
            <Navbar.Brand as={Link} to="/" href="#home">
              <img
                src="https://tidrappisol.blob.core.windows.net/sella/Asset 2.svg"
                alt="logo"
                style={{ width: 100 }}
              />
            </Navbar.Brand>
          </div>
          <Navbar className=" ">
            <Nav className="">
              <Nav.Item className="ml-lg-2 pt-2 pb-2 pt-md-0 pb-md-0">
                {isLoggedIn ? (
                  <Button variant="primary" onClick={handleLogout}>
                    Logga ut
                  </Button>
                ) : (
                  <Button onClick={handleLogin}>Logga in</Button>
                )}
              </Nav.Item>
            </Nav>
          </Navbar>
        </div>
      </Navbar>
      <Navbar
        bg="primary"
        className="py-3 justify-content-center"
        // collapseOnSelect
        // expand="lg"
        // justify="true"
        id="responsive-navbar-nav"
      >
        <div
          className="container-md justify-content-around text-uppercase font-weight-bold"
          // justify="true"
        >
          <Nav.Link className="text-light" as={Link} to="/dashboard">
            Funktioner
          </Nav.Link>

          <Nav.Link className="text-light" as={Link} to="/dashboard">
            Kontakt
          </Nav.Link>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        </div>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      </Navbar>
    </>
  );
}
