import Lottie from "lottie-react";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ConnectFortnoxService, DataSourceType } from "../services/openapi";
import lottie from "../Lotties/89540-green-check.json";
import { Col, Row } from "react-bootstrap";

const Fortnoxcallback = () => {
  const [result, setResult] = useState("not configured");
  const [searchParams] = useSearchParams();
  var code = searchParams.get("code");
  var dataSourceType: DataSourceType | null = searchParams.get(
    "state"
  ) as DataSourceType;

  useEffect(() => {
    if (code && dataSourceType) {
      ConnectFortnoxService.getFortnoxcallback(code, dataSourceType).then(
        (response) => {
          setResult(response);
        }
      );
    }
  }, [code, dataSourceType]);

  return (
    <Row>
      <Col xs={4} className="mt-3 align-self-center">
        {result != "not configured" && <Lottie animationData={lottie}></Lottie>}
      </Col>
    </Row>
  );
};

export default Fortnoxcallback;
