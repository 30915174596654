import { useEffect, useState } from "react";
import { ListGroup, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  CustomerList,
  CustomerMetaOptionsListMeta,
  CustomersService,
} from "../../services/openapi";
import PaginationList from "../PaginationList";
import SearchBar from "../SearchBar";

function CustomersList() {
  const defaultCustomerList: CustomerList = {
    data: null,
    meta: {
      currentPage: 1,
      pageSize: 30,
    } as CustomerMetaOptionsListMeta,
  };
  const [data, setData] = useState<CustomerList>(defaultCustomerList);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      setIsLoading(true);
      CustomersService.getApiV1Customers(
        data.meta.currentPage,
        data.meta.pageSize,
        data?.meta.searchTerm ?? ""
      ).then((response) => {
        setData(response);
        setIsLoading(false);
      });
    }
  }, [data?.meta.currentPage, data?.meta.pageSize, data?.meta.searchTerm]);

  const customers =
    data?.data &&
    data.data.length > 0 &&
    data.data.map((item) => (
      <ListGroup.Item
        key={item.customerNumber}
        as={Link}
        to={"/customers/details/" + item.customerNumber}
        className="d-flex justify-content-between align-items-center"
        action
      >
        <div>
          <h5 className="card-title">{item.name}</h5>
          <p className="card-text">Kundnummer: {item.customerNumber}</p>
        </div>
      </ListGroup.Item>
    ));
  const handleChangePage = (page: number) => {
    setData({
      ...data,
      meta: {
        ...data.meta,
        currentPage: page,
        // options: {
        //   ...data.meta.options,
        // },
      },
    });
  };
  const handleSearch = (searchTerm: string) => {
    if (data && data.meta) {
      setData({
        ...data,
        meta: {
          ...data.meta,
          searchTerm: searchTerm,
          options: {
            ...data.meta.options,
          },
        },
      });
    }
  };
  return (
    <>
      <SearchBar filter={false} handleSearch={handleSearch} />
      <ListGroup className="mt-2">{data && customers}</ListGroup>
      {data?.data && data.meta ? (
        <PaginationList
          totalPages={data.meta.totalNumberOfPages || 0}
          currentPage={data.meta.currentPage || 0}
          handleChangePage={handleChangePage}
          totalItems={data.meta.totalNumberOfResults || 0}
        />
      ) : (
        <ListGroup className="mt-2">
          <Spinner
            className="align-self-center mt-3"
            animation="border"
            variant="primary"
          />
        </ListGroup>
      )}
    </>
  );
}

export default CustomersList;
