import { useContext } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AppVariableContext } from "../AppVariableContext";

export default function Dashboard() {
  const appVariables = useContext(AppVariableContext);

  return (
    <Container fluid="lg h-100">
      <Row className="pt-5 d-flex justify-content-center">
        <Col md="6" className="text-center pt-5 mt-5">
          <h1>Hej {appVariables?.appVariables.userGivenName}!</h1>
          <Link to="orders/new">
            <Button variant="info" className="mt-5">
              Skapa order
            </Button>
          </Link>
        </Col>
      </Row>
    </Container>
  );
}
