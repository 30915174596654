import { Button, Col, Row } from "react-bootstrap";
import { OrderFilterOption } from "../../services/openapi";

export interface OrderFilterRowProps {
  handleFilterChange: (filter: OrderFilterOption) => void;
  selectedFilter?: OrderFilterOption;
}

export default function OrderFilterRow(props: OrderFilterRowProps) {
  if (props) {
    return (
      <Row className="mt-2">
        <Col>
          <FilterButton
            handleFilterChange={props.handleFilterChange}
            Filter={OrderFilterOption.ALL}
            Text="Alla"
            Class="btn-light"
            selectedFilter={props.selectedFilter}
          />
          <FilterButton
            handleFilterChange={props.handleFilterChange}
            Filter={OrderFilterOption.NOT_DELIVERED_INVOICE_NOT_CREATED}
            Text="Ej levererade ej fakturerade"
            Class="btn-half-yellow"
            selectedFilter={props.selectedFilter}
          />
          <FilterButton
            handleFilterChange={props.handleFilterChange}
            Filter={OrderFilterOption.DELIVERED_INVOICE_NOT_CREATED}
            Text="Levererade ej fakturerade"
            Class="btn-light-yellow"
            selectedFilter={props.selectedFilter}
          />
          <FilterButton
            handleFilterChange={props.handleFilterChange}
            Filter={OrderFilterOption.INVOICE_NOT_CREATED}
            Text="Ej fakturerade"
            Class="btn-half-red"
            selectedFilter={props.selectedFilter}
          />
          <FilterButton
            handleFilterChange={props.handleFilterChange}
            Filter={OrderFilterOption.INVOICE_NOT_CREATED_AND_OVERDUE}
            Text="Ej fakturerade förfallna"
            Class="btn-light-red"
            selectedFilter={props.selectedFilter}
          />
          <FilterButton
            handleFilterChange={props.handleFilterChange}
            Filter={OrderFilterOption.INVOICE_CREATED}
            Text="Fakturerade"
            Class="btn-light-green"
            selectedFilter={props.selectedFilter}
          />
          <FilterButton
            handleFilterChange={props.handleFilterChange}
            Filter={OrderFilterOption.CANCELLED}
            Text="Makulerade"
            Class="btn-light-grey"
            selectedFilter={props.selectedFilter}
          />
        </Col>
      </Row>
    );
  }
  return <></>;
}

interface FilterButtonProps {
  Class: string;
  Filter: OrderFilterOption;
  Text: string;
  handleFilterChange: (filter: OrderFilterOption) => void;
  selectedFilter?: OrderFilterOption;
}

const FilterButton = (props: FilterButtonProps) => {
  return (
    <Button
      size="sm"
      className={
        props.Class +
        " ms-1 " +
        (props.selectedFilter === props.Filter && "border border-dark")
      }
      onClick={() => props.handleFilterChange(props.Filter)}
    >
      {props.Text}
    </Button>
  );
};
