import { Row, Col, Form, InputGroup, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form/dist/types/form";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { useState } from "react";
import { Order, Contact, ContactsService } from "../services/openapi";
import { faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ContactInputProps {
  contacttype: string;
  name?: string;
  phone?: string;
  register: UseFormRegister<any>;
  getValues: UseFormGetValues<Order>;
  setValues: UseFormSetValue<Order>;
}

const ContactInput = (props: ContactInputProps) => {
  const [isLoadingContact, setIsLoadingContact] = useState(false);
  const [Contacts, setContacts] = useState<Contact[]>([]);

  const handleSearch = (nameSearch: string) => {
    setIsLoadingContact(true);
    var customerNr = props.getValues("customerId");
    if (customerNr) {
      ContactsService.getApiV1Contacts(1, 10, customerNr, nameSearch).then(
        (result) => {
          if (result.data) {
            //set null names to empty string
            result.data.forEach((x) => {
              if (x.name == null) x.name = "";
            });
            setContacts(result.data);
          } else {
            setContacts([]);
          }
          setIsLoadingContact(false);
        }
      );
    }
  };

  const handleContactChange = (selected: Contact) => {
    var contactfield = props.contacttype ? props.contacttype : ("" as any);
    props.setValues(contactfield, selected);
    props.getValues(contactfield);
  };

  const handleManualChange = (fieldname: string, value: string) => {
    var contactfield = (props.contacttype + "." + fieldname) as "customerName";
    props.setValues(contactfield, value);
    props.getValues(contactfield);
    props.setValues(
      (props.contacttype + "." + "id") as "deliveryAddress.id",
      null
    );
  };

  const handleDelete = (id: string) => {
    ContactsService.deleteApiV1Contacts(id).then((result) => {
      if (result) {
        //remove result.data from Addresses
        setContacts(Contacts.filter((a) => a.id !== id));
      }
    });
  };

  return (
    <Row>
      <Col md="4" className="mb-2">
        <Form.Group>
          <InputGroup>
            <InputGroup.Text id="basic-addon-2">
              👤
              <FontAwesomeIcon className="ps-1" icon={faSearch} />
            </InputGroup.Text>
            <AsyncTypeahead
              id="contacttype"
              options={Contacts}
              onSearch={handleSearch}
              isLoading={isLoadingContact}
              placeholder="Kontaktnamn"
              useCache={false}
              // @ts-ignore
              labelKey={(option: Contact) => `${option.name}`}
              // @ts-ignore
              renderMenuItemChildren={(option: Contact) => (
                <div className="d-flex justify-content-between">
                  <div className="">
                    <small>
                      <strong>Namn:</strong> {option.name}
                    </small>
                    <br />
                    <small>
                      <strong>Tel:</strong> {option.phone}
                    </small>
                    <br />
                    <small>
                      <strong>Mail:</strong> {option.email}
                    </small>
                  </div>
                  <div className="d-flex align-items-center">
                    {option.id && (
                      <Button
                        size="sm"
                        variant="danger"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDelete(option.id ?? "");
                        }}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    )}
                  </div>
                </div>
              )}
              defaultInputValue={
                (props.getValues(
                  (props.contacttype + ".name") as any
                ) as string) ?? ""
              }
              onInputChange={(text) => handleManualChange("name", text)}
              onChange={(selected) => {
                handleContactChange(selected[0] as Contact);
              }}
            />
          </InputGroup>
        </Form.Group>
      </Col>
      <Col md="4" className="mb-2">
        <Form.Group>
          <InputGroup>
            <InputGroup.Text id="basic-addon1">📞</InputGroup.Text>
            <Form.Control
              autoComplete="none"
              aria-describedby="basic-addon1"
              {...props.register(`${props.contacttype}.phone`)}
            />
          </InputGroup>
        </Form.Group>
      </Col>
      <Col md="4" className="mb-2">
        <Form.Group>
          <InputGroup>
            <InputGroup.Text id="basic-addon1">📧</InputGroup.Text>
            <Form.Control
              autoComplete="none"
              aria-describedby="basic-addon1"
              {...props.register(`${props.contacttype}.email`)}
            />
          </InputGroup>
        </Form.Group>
      </Col>
    </Row>
  );
};

ContactInput.propTypes = {
  register: PropTypes.func,
  fieldname: PropTypes.string,
};

export default ContactInput;
