/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Invoice } from '../models/Invoice';
import type { InvoiceFromOrdersResult } from '../models/InvoiceFromOrdersResult';
import type { Order } from '../models/Order';
import type { OrderFilterOption } from '../models/OrderFilterOption';
import type { OrderList } from '../models/OrderList';
import type { OrderSortingOption } from '../models/OrderSortingOption';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OrdersService {

    /**
     * @param page 
     * @param pageSize 
     * @param customerId 
     * @param searchTerm 
     * @param sortBy 
     * @param descending 
     * @param filterBy 
     * @param extensionPropertiesSearch 
     * @returns OrderList Success
     * @throws ApiError
     */
    public static getApiV1Orders(
page: number = 1,
pageSize: number = 30,
customerId: string = '',
searchTerm: string = '',
sortBy?: OrderSortingOption,
descending: boolean = true,
filterBy?: OrderFilterOption,
extensionPropertiesSearch?: string,
): CancelablePromise<OrderList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Orders',
            query: {
                'page': page,
                'pageSize': pageSize,
                'customerId': customerId,
                'searchTerm': searchTerm,
                'sortBy': sortBy,
                'descending': descending,
                'filterBy': filterBy,
                'ExtensionPropertiesSearch': extensionPropertiesSearch,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Order Success
     * @throws ApiError
     */
    public static postApiV1Orders(
requestBody?: Order,
): CancelablePromise<Order> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Orders',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns Order Success
     * @throws ApiError
     */
    public static getApiV1Orders1(
id: string,
): CancelablePromise<Order> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Orders/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns Order Success
     * @throws ApiError
     */
    public static putApiV1Orders(
id: string,
requestBody?: Order,
): CancelablePromise<Order> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/Orders/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns Invoice Success
     * @throws ApiError
     */
    public static postApiV1OrdersInvoice(
requestBody?: Array<string>,
): CancelablePromise<Invoice> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Orders/Invoice',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns InvoiceFromOrdersResult Success
     * @throws ApiError
     */
    public static getApiV1OrdersInvoiceDelivered(): CancelablePromise<Array<InvoiceFromOrdersResult>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Orders/Invoice/Delivered',
        });
    }

}
