import { Button, Col, Row } from "react-bootstrap";
import { InvoiceFilterOption } from "../../services/openapi";

export interface InvoiceFilterRowProps {
  handleFilterChange: (filter: InvoiceFilterOption) => void;
  selectedFilter?: InvoiceFilterOption;
}

export default function InvoiceFilterRow(props: InvoiceFilterRowProps) {
  if (props) {
    return (
      <Row className="mt-2">
        <Col>
          <Button
            size="sm"
            className={
              "btn-light " +
              (props.selectedFilter === InvoiceFilterOption.ALL
                ? "border border-dark"
                : "")
            }
            onClick={() => props.handleFilterChange(InvoiceFilterOption.ALL)}
          >
            Alla
          </Button>
          <Button
            size="sm"
            className={
              "btn-light btn-light-yellow ms-1 " +
              (props.selectedFilter === InvoiceFilterOption.UN_BOOKED
                ? "border border-dark"
                : "")
            }
            active={props.selectedFilter === InvoiceFilterOption.UN_BOOKED}
            onClick={() =>
              props.handleFilterChange(InvoiceFilterOption.UN_BOOKED)
            }
          >
            Ej bokförda
          </Button>
          <Button
            size="sm"
            className={
              "btn-light btn-half-red ms-1 " +
              (props.selectedFilter === InvoiceFilterOption.UN_PAID
                ? "border border-dark"
                : "")
            }
            onClick={() =>
              props.handleFilterChange(InvoiceFilterOption.UN_PAID)
            }
          >
            Obetalda
          </Button>
          <Button
            size="sm"
            className={
              "btn-light btn-light-red ms-1 " +
              (props.selectedFilter === InvoiceFilterOption.UN_PAID_AND_OVERDUE
                ? "border border-dark"
                : "")
            }
            onClick={() =>
              props.handleFilterChange(InvoiceFilterOption.UN_PAID_AND_OVERDUE)
            }
          >
            Obetalda Förfallna
          </Button>
          <Button
            size="sm"
            className={
              "btn-light btn-light-green ms-1 " +
              (props.selectedFilter === InvoiceFilterOption.FULLY_PAID
                ? "border border-dark"
                : "")
            }
            onClick={() =>
              props.handleFilterChange(InvoiceFilterOption.FULLY_PAID)
            }
          >
            Slutbetalda
          </Button>
          <Button
            size="sm"
            className={
              "btn-secondary ms-1 " +
              (props.selectedFilter === InvoiceFilterOption.CANCELLED
                ? "border border-dark"
                : "")
            }
            onClick={() =>
              props.handleFilterChange(InvoiceFilterOption.CANCELLED)
            }
          >
            Makulerade
          </Button>
        </Col>
      </Row>
    );
  }
  return <></>;
}
