/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrderDocumentType } from '../models/OrderDocumentType';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OrderPrintService {

    /**
     * @param id
     * @param documentType
     * @returns binary Success
     * @throws ApiError
     */
    public static getApiV1OrderPrint(
        id: number,
        documentType?: OrderDocumentType,
    ): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/OrderPrint/{id}',
            path: {
                'id': id,
            },
            query: {
                'documentType': documentType,
            },
        });
    }

    /**
     * @param id
     * @param documentType
     * @param email
     * @param comment
     * @returns any Success
     * @throws ApiError
     */
    public static getEmail(
        id: number,
        documentType?: OrderDocumentType,
        email?: string,
        comment?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/email/{id}',
            path: {
                'id': id,
            },
            query: {
                'documentType': documentType,
                'email': email,
                'comment': comment,
            },
        });
    }

}
