/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Price } from '../models/Price';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PriceService {

    /**
     * @param articleNumber 
     * @param pricelistCode 
     * @returns Price Success
     * @throws ApiError
     */
    public static getApiV1Price(
articleNumber?: string,
pricelistCode?: string,
): CancelablePromise<Price> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Price',
            query: {
                'articleNumber': articleNumber,
                'pricelistCode': pricelistCode,
            },
        });
    }

}
