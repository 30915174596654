import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useMsal } from "@azure/msal-react";
import { AppVariableContext } from "../AppVariableContext";

export default function NavMenuSignedIn() {
  const { instance } = useMsal();
  const appVariables = useContext(AppVariableContext);

  const [expanded, setExpanded] = useState(false);
  var request = {
    scopes: [
      "https://cabanossyorg.onmicrosoft.com/fb6c66d0-6291-4a66-a24f-f231fc5b63ad/User.Test",
    ],
    account: instance.getAllAccounts()[0],
  };

  const handleLogin = () => {
    instance.acquireTokenSilent(request).catch((error) => {
      if (error) {
        // fallback to interaction when silent call fails
        return instance.acquireTokenRedirect(request).catch((error) => {
          // Acquire token interactive failure
          console.log(error);
        });
      }
    });
  };

  const handleLogout = () => {
    instance
      .logoutRedirect({ postLogoutRedirectUri: "/" })
      .catch((error) => console.log(error));
  };

  let isLoggedIn = instance.getAllAccounts().length > 0;
  return (
    <>
      <Navbar
        // bg="light"
        className="py-2"
        collapseOnSelect
        expand="lg"
        expanded={expanded}
        // justify="true"
      >
        <div className="container-md justify-content-between">
          <div>
            <Navbar.Brand as={Link} to="/" href="#home">
              <img
                src="https://tidrappisol.blob.core.windows.net/sella/Asset 3.svg"
                alt="logo"
                style={{ width: 60 }}
              />
            </Navbar.Brand>
            {
              <Navbar.Text className="h6 d-none d-sm-inline-block">
                Hej{" "}
                {" " +
                  appVariables?.appVariables.userGivenName +
                  " " +
                  appVariables?.appVariables.userSurname +
                  "!"}
              </Navbar.Text>
            }
            {/* <Navbar.Brand as={Link} to="/" href="#home">
              <img
                src="https://tidrappisol.blob.core.windows.net/sella/Asset 2.svg"
                alt="logo"
                style={{ width: 100 }}
              />
            </Navbar.Brand> */}
          </div>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={() => setExpanded(expanded ? false : true)}
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <Row className="d-lg-none py-3">
              <Col xs={6} className="d-flex justify-content-center py-3">
                <Link
                  className="w-100"
                  to="/customers"
                  onClick={() => setExpanded(false)}
                >
                  <Button variant="warning" className="w-100">
                    Kunder
                  </Button>
                </Link>
              </Col>
              {/* <Col xs={6} className="d-flex justify-content-center py-3">
                <Link
                  className="w-100"
                  to="/articles"
                  onClick={() => setExpanded(false)}
                >
                  <Button variant="danger" className="w-100">
                    Artiklar
                  </Button>
                </Link>
              </Col> */}
              <Col xs={6} className="d-flex justify-content-center py-3">
                <Link
                  className="w-100"
                  to="/orders"
                  onClick={() => setExpanded(false)}
                >
                  <Button variant="info" className="w-100">
                    Ordrar
                  </Button>
                </Link>
              </Col>
              <Col xs={6} className="d-flex justify-content-center py-3">
                <Link
                  className="w-100"
                  to="/invoices"
                  onClick={() => setExpanded(false)}
                >
                  <Button variant="success" className="w-100">
                    Fakturor
                  </Button>
                </Link>
              </Col>
              <Col xs={12} className="d-flex pt-3 justify-content-center">
                <Link
                  className="w-100 d-flex links justify-content-center"
                  to="/companysettings"
                  onClick={() => setExpanded(false)}
                >
                  <Button
                    className="text-secondary bg-transparent border-0 "
                    variant="light"
                  >
                    Dina val
                  </Button>
                </Link>
              </Col>
              <Col xs={12} className="d-flex justify-content-center">
                {isLoggedIn ? (
                  <Button
                    variant="light "
                    className="w-50 bg-transparent border-0"
                    onClick={handleLogout}
                  >
                    Logga ut
                  </Button>
                ) : (
                  <Button onClick={handleLogin}>Logga in</Button>
                )}
              </Col>
            </Row>
          </Navbar.Collapse>
          <Link
            className="d-none d-lg-inline-block me-2 text-secondary"
            to="/companysettings"
          >
            <Button
              className="d-none d-lg-inline-block text-secondary bg-transparent border-0"
              variant="light"
            >
              Dina val
            </Button>
          </Link>
          <Link className="d-none d-lg-inline-block me-2" to="/customers">
            <Button variant="danger">Kunder</Button>
          </Link>
          {/* <Link className="d-none d-lg-inline-block me-2" to="/articles">
            <Button variant="warning">Artiklar</Button>
          </Link> */}
          <Link className="d-none d-lg-inline-block me-2" to="/orders">
            <Button variant="info">Ordrar</Button>
          </Link>
          <Link className="d-none d-lg-inline-block me-2" to="/invoices">
            <Button variant="success">Fakturor</Button>
          </Link>
          {isLoggedIn ? (
            <Button
              className="d-none d-lg-inline-block"
              variant="primary"
              onClick={handleLogout}
            >
              Logga ut
            </Button>
          ) : (
            <Button onClick={handleLogin}>Logga in</Button>
          )}
        </div>
      </Navbar>
    </>
  );
}
