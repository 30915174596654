import { faSearch, faSearchPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useState } from "react";
import { InputGroup, Form, Button } from "react-bootstrap";

interface SearchBarProps {
  handleSearch?: (searchterm: string) => void;
  handleAdvancedSearch?: (searchterm: string) => void;
  children?: React.ReactNode;
  isAdvancedSearch?: boolean;
}

const SearchBar = (props: SearchBarProps) => {
  const [searchTerm, setSearchTerm] = useState<string>("");

  const handleKeyPress = (target: React.KeyboardEvent<HTMLInputElement>) => {
    if (target.key == "Enter") {
      props.handleSearch?.(searchTerm);
    }
  };

  return (
    <InputGroup className="mr-auto">
      {props.children}
      <Form.Control
        type="text"
        id="search"
        placeholder="Sök"
        onKeyDown={handleKeyPress}
        onChange={(e) => setSearchTerm(e.currentTarget.value)}
      />
      {props.handleAdvancedSearch && (
        <Button
          variant={props.isAdvancedSearch ? "warning" : "outline-secondary"}
          id="button-addon1"
          onClick={() => props.handleAdvancedSearch?.(searchTerm)}
        >
          <FontAwesomeIcon icon={faSearchPlus}></FontAwesomeIcon>
        </Button>
      )}
      <Button
        variant="secondary"
        id="button-addon1"
        onClick={() => props.handleSearch?.(searchTerm)}
      >
        <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
      </Button>
    </InputGroup>
  );
};

SearchBar.propTypes = {
  showFinished: PropTypes.bool,
  handleShowFinished: PropTypes.func,
  handleSearch: PropTypes.func,
  filter: PropTypes.bool,
};

export default SearchBar;
