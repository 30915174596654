import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { Card, Col, Container, Row, Spinner, Tab, Tabs } from "react-bootstrap";
import { Customer, CustomersService } from "../../services/openapi";
import OrdersList from "../Order/OrdersList";
import { useEffect, useState } from "react";
import InvoicesList from "../Invoice/InvoicesList";

function CustomersDetails() {
  const params = useParams();

  const [data, setData] = useState<Customer>();

  useEffect(() => {
    CustomersService.getApiV1Customers1(params.id ?? "").then((response) => {
      setData(response);
    });
  }, [params.id]);

  return data ? (
    <Container fluid="lg">
      <h1 className="mb-3">{data.name}</h1>
      <Card className="mb-2">
        <Card.Body>
          <Row>
            <Col>
              <p>
                <strong>Organisationsnummer:</strong> {data.organisationNumber}
              </p>
              <p>
                <strong>Telefon:</strong> {data.phone1}
              </p>
              <p>
                <strong>Telefon2:</strong> {data.phone2}
              </p>
              <span>
                <strong>E-post faktura:</strong> {data.emailInvoice}
              </span>
            </Col>
            <Col>
              <p>
                <strong>Adress 1:</strong> {data.address1}
              </p>
              <p>
                <strong>Adress 2:</strong> {data.address2}
              </p>
              <p>
                <strong> Ort: </strong>
                {data.city} <strong>Postnummer:</strong> {data.zipCode}
              </p>
              <span>
                <strong>Land: </strong>
                {data.country}
              </span>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {/* <Link to={"/orders/new/" + params.id}>
        <Button variant="info">Ny Order</Button>
      </Link>
      <Button className="ms-1" variant="success">
        Ny Faktura
      </Button> */}
      <Tabs
        defaultActiveKey="orders"
        transition={false}
        id="noanim-tab-example"
        className="mb-3 pt-1"
        mountOnEnter={true}
      >
        <Tab eventKey="orders" title="Ordrar">
          <OrdersList customerId={params.id} />
        </Tab>
        <Tab eventKey="quotes" title="Fakturor">
          <InvoicesList customerId={params.id} />
        </Tab>
      </Tabs>
    </Container>
  ) : (
    <Container fluid="lg">
      <Spinner animation="border" variant="primary" />
    </Container>
  );
}

CustomersDetails.propTypes = {
  id: PropTypes.string,
};

export default CustomersDetails;
