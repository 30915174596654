import { Form } from "react-bootstrap";
import { AppVariables, Provider } from "../services/openapi";

interface SellaSourceOptionsProps {
  appvariables: AppVariables;
  setAppVariables: (newVars: AppVariables) => void;
  providers: Provider[];
}

const SellaSourceOptions = (props: SellaSourceOptionsProps) => {
  let sellaprovider = props.providers.find((p) => p.id === "Sella");
  return sellaprovider ? (
    <Form>
      <Form.Group className="mb-2">
        <Form.Check
          type="switch"
          label="Ordrar"
          checked={
            props.appvariables.orderServiceProviderId == sellaprovider.id
          }
          onChange={(e) => {
            props.appvariables.orderServiceProviderId = e.target.checked
              ? sellaprovider?.id
              : null;
            props.setAppVariables(props.appvariables);
          }}
        />
      </Form.Group>
      {/* <Form.Group className="mb-2">
        <Form.Check
          type="switch"
          label="Kunder"
          checked={
            props.appvariables.customerServiceProviderId == sellaprovider.id
          }
          onChange={(e) => {
            props.appvariables.customerServiceProviderId = e.target.checked
              ? sellaprovider?.id
              : null;
            props.setAppVariables(props.appvariables);
          }}
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Check
          type="switch"
          label="Offerter"
          checked={
            props.appvariables.quoteServiceProviderId == sellaprovider.id
          }
          onChange={(e) => {
            props.appvariables.quoteServiceProviderId = e.target.checked
              ? sellaprovider?.id
              : null;
            props.setAppVariables(props.appvariables);
          }}
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Check
          type="switch"
          label="Artiklar"
          checked={
            props.appvariables.articleServiceProviderId == sellaprovider.id
          }
          onChange={(e) => {
            props.appvariables.articleServiceProviderId = e.target.checked
              ? sellaprovider?.id
              : null;
            props.setAppVariables(props.appvariables);
          }}
        />
      </Form.Group> */}
    </Form>
  ) : (
    <span>Kunde inte hitta sella bland källor</span>
  );
};

export default SellaSourceOptions;
