import PropTypes from "prop-types";

export default function Layout(props: { children: JSX.Element }) {
  return (
    <div>
      <div className="pt-1 pt-m-4 pb-3 pb-md-5">{props.children}</div>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node,
};
