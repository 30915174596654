/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum OrderFilterOption {
    ALL = 'All',
    NOT_DELIVERED_INVOICE_NOT_CREATED = 'NotDeliveredInvoiceNotCreated',
    DELIVERED_INVOICE_NOT_CREATED = 'DeliveredInvoiceNotCreated',
    INVOICE_NOT_CREATED = 'InvoiceNotCreated',
    INVOICE_NOT_CREATED_AND_OVERDUE = 'InvoiceNotCreatedAndOverdue',
    INVOICE_CREATED = 'InvoiceCreated',
    CANCELLED = 'Cancelled',
}
