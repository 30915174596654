import { Button, Card, Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import OrderFields from "./Settings/OrderFields";
// import CustomerFields from "./Settings/CustomerFields";
// import OfferFields from "./Settings/OfferFields";
import { useContext, useEffect, useState } from "react";
import {
  Provider,
  ProvidersService,
  DataSourceType,
} from "../services/openapi";
import { AppVariableContext } from "../AppVariableContext";
import SoftwareIcon from "./SoftwareIcon";
import SellaSourceOptions from "./SellaSourceOptions";

function CompanySettings() {
  //use appsettings context
  const appVars = useContext(AppVariableContext);
  const appvariables = appVars?.appVariables;
  const [providers, setProviders] = useState<Provider[]>([]);
  const setAppVariables = appVars?.setAppVariables;
  useEffect(() => {
    ProvidersService.getApiV1Providers().then((response) => {
      setProviders(response);
    });
  }, []);

  const HandleDisconnect = (SourceType: DataSourceType) => {
    if (appvariables && setAppVariables) {
      switch (SourceType) {
        case DataSourceType.ACCOUNTING_SOFTWARE:
          appvariables.accountingServiceProviderId = undefined;
          break;
        case DataSourceType.ORDER:
          appvariables.orderServiceProviderId = null;
          break;
        case DataSourceType.CUSTOMER:
          appvariables.customerServiceProviderId = null;
          break;
        case DataSourceType.QUOTE:
          appvariables.quoteServiceProviderId = null;
          break;
        case DataSourceType.ARTICLE:
          appvariables.articleServiceProviderId = null;
          break;
        default:
          throw new Error("Unknown service type");
      }
      setAppVariables(appvariables);
    }
  };

  if (appvariables && setAppVariables && providers.length > 0) {
    return (
      <Container className="pb-5">
        <Row className="pt-5 pb-5 text-center">
          <h1>Företagsinställningar</h1>
          <p>På denna sida kan du ändra inställningarna för ditt företag.</p>
        </Row>
        <Row className="pt-5 pb-5 d-flex text-center justify-content-center">
          {/* <h3>Nuvarande kopplingar</h3> */}
          {/* <p>Från källorna nedan hämtas informationen du ser i Sella.</p> */}
          <Col>
            <Row className="pt-4 d-flex justify-content-center">
              <Col xs={12} md={6} lg={4}>
                <Card className="h-100">
                  <Card.Body>
                    <h4>Bokföringssystem</h4>
                    <Row className="d-flex justify-content-center mb-2">
                      {appvariables.accountingServiceProviderId ? (
                        <Col
                          xs="3"
                          className="d-flex align-items-center flex-column"
                        >
                          <SoftwareIcon
                            Provider={
                              providers.find(
                                (p) =>
                                  p.id ==
                                  appvariables.accountingServiceProviderId
                              ) as Provider
                            }
                          />
                          <Button
                            className="text-nowrap mt-2"
                            onClick={() =>
                              HandleDisconnect(
                                DataSourceType.ACCOUNTING_SOFTWARE
                              )
                            }
                            size="sm"
                          >
                            Koppla bort
                          </Button>
                        </Col>
                      ) : (
                        <Row className="d-flex justify-content-center">
                          <p>Välj ditt Bokföringssystem</p>
                          {providers
                            .filter((p) =>
                              p.dataSourceTypes?.includes(
                                DataSourceType.ACCOUNTING_SOFTWARE
                              )
                            )
                            .map((provider) => (
                              <Col
                                xs="3"
                                className="d-flex align-items-center"
                                key={provider.id}
                              >
                                <SoftwareIcon
                                  key={provider.id}
                                  Provider={provider}
                                />
                              </Col>
                            ))}
                        </Row>
                      )}
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="pt-5 d-flex text-center justify-content-center">
          <h3>Lagring</h3>
          <p>
            Välj vad du vill lagra i Sella. Det du lagrar i Sella kan du utöka
            med extra fält.
          </p>
        </Row>
        <Row className=" pb-5 d-flex justify-content-center">
          <Col xs={12} md={6} lg={4}>
            <Card className="h-100">
              <Card.Body>
                <SellaSourceOptions
                  appvariables={appvariables}
                  setAppVariables={setAppVariables}
                  providers={providers}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="pt-5 d-flex text-center justify-content-center">
          <h3>Extrafält</h3>
          <p>Här kan du hantera vilka extrafält du vill använda</p>
        </Row>
        <Row className="pt-4">
          <Col>
            <Card className="p-3">
              <Tabs
                defaultActiveKey="order"
                id="uncontrolled-tab-example"
                className=""
              >
                <Tab eventKey="order" title="Order">
                  <OrderFields
                    appvariables={appvariables}
                    setAppVariables={setAppVariables}
                  />
                </Tab>
                {/* <Tab eventKey="kund" title="Kund">
                  <CustomerFields />
                </Tab>
                <Tab eventKey="offert" title="Offert">
                  <OfferFields />
                </Tab> */}
              </Tabs>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  } else {
    return <div></div>;
  }
}

export default CompanySettings;
