/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Address } from '../models/Address';
import type { AddressList } from '../models/AddressList';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AddressesService {

    /**
     * @param page 
     * @param pageSize 
     * @param customerId 
     * @param recipientSearchTerm 
     * @param addressSearchTerm 
     * @returns AddressList Success
     * @throws ApiError
     */
    public static getApiV1Addresses(
page: number = 1,
pageSize: number = 30,
customerId: string = '',
recipientSearchTerm: string = '',
addressSearchTerm: string = '',
): CancelablePromise<AddressList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Addresses',
            query: {
                'page': page,
                'pageSize': pageSize,
                'customerId': customerId,
                'recipientSearchTerm': recipientSearchTerm,
                'addressSearchTerm': addressSearchTerm,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Address Success
     * @throws ApiError
     */
    public static putApiV1Addresses(
requestBody?: Address,
): CancelablePromise<Address> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/Addresses',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns Address Success
     * @throws ApiError
     */
    public static postApiV1Addresses(
requestBody?: Address,
): CancelablePromise<Address> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Addresses',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns Address Success
     * @throws ApiError
     */
    public static getApiV1Addresses1(
id: string,
): CancelablePromise<Address> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Addresses/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @returns boolean Success
     * @throws ApiError
     */
    public static deleteApiV1Addresses(
id: string,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/Addresses/{id}',
            path: {
                'id': id,
            },
        });
    }

}
