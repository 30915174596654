import { createContext } from "react";
import { AppVariables } from "./services/openapi";

export interface AppVariableContextProps {
  appVariables: AppVariables;
  setAppVariables: (newVars: AppVariables) => void;
}

export const AppVariableContext = createContext<AppVariableContextProps | null>(
  null
);
