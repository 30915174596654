import { useEffect, useState } from "react";
import { Button, ListGroup, Spinner } from "react-bootstrap";
import {
  Invoice,
  InvoiceFilterOption,
  InvoiceList,
  InvoiceMetaOptionsListMeta,
  InvoiceService,
} from "../../services/openapi";
import PaginationList from "../PaginationList";
import { InvoiceListItem } from "./InvoiceListItem";
import SearchBar from "../SearchBar";
import InvoiceFilterRow from "./InvoiceFilterRow";
import { GroupedInvoiceModal } from "./GroupedInvoiceModal";
// import { OrderFilterDropdown } from "./OrderFilterDropdown";

function InvoicesList(props: { customerId?: string }) {
  const defaultInvoiceList: InvoiceList = {
    data: null,
    meta: {
      options: {
        filterOption: InvoiceFilterOption.ALL,
        descending: true,
        customerId: props.customerId,
      },
    } as InvoiceMetaOptionsListMeta,
  };
  const [data, setData] = useState<InvoiceList>(defaultInvoiceList);
  const [isLoading, setIsLoading] = useState(false);
  const [showGroupInvoiceModal, setShowGroupInvoiceModal] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      setIsLoading(true);
      InvoiceService.getApiV1Invoice(
        data.meta.currentPage,
        data.meta.pageSize,
        data?.meta.options?.customerId ?? "",
        data?.meta.options?.descending ?? true,
        data?.meta.options?.filterOption ?? InvoiceFilterOption.ALL
      ).then((response) => {
        setData(response);
        setIsLoading(false);
      });
    }
  }, [
    props.customerId,
    data?.meta.options?.filterOption,
    data?.meta.currentPage,
    data?.meta.pageSize,
  ]);

  const handleChangePage = (page: number) => {
    setData({
      ...data,
      meta: {
        ...data.meta,
        currentPage: page,
        // options: {
        //   ...data.meta.options,
        // },
      },
    });
  };

  //   const handleSortChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //     if (data && data.meta) {
  //       setData({
  //         ...data,
  //         meta: {
  //           ...data.meta,
  //           options: {
  //             ...data.meta.options,
  //             sortOption: event.currentTarget.value as OrderSortingOption,
  //             sortDescending: !data.meta.options.sortDescending,
  //           },
  //         },
  //       });
  //     }
  //   };

  const handleFilterChange = (filter: InvoiceFilterOption) => {
    // if (!isLoading) {
    if (data && data.meta) {
      setData({
        ...data,
        meta: {
          ...data.meta,
          currentPage: 1,
          options: {
            ...data.meta.options,
            filterOption: filter,
          },
        },
      });
      // }
    }
  };
  const handleSearch = (searchterm: string) => {
    if (data && data.meta) {
      setData({
        ...data,
        meta: {
          ...data.meta,
          searchTerm: searchterm,
          options: {
            ...data.meta.options,
          },
        },
      });
    }
  };

  return (
    <>
      <div className="d-flex">
        <Button
          className="me-2"
          variant="success"
          onClick={() => setShowGroupInvoiceModal(true)}
        >
          Skapa
        </Button>
        <GroupedInvoiceModal
          show={showGroupInvoiceModal}
          onHide={() => setShowGroupInvoiceModal(false)}
        />
        <SearchBar handleSearch={handleSearch}>
          {/* <InvoiceFilterDropdown
            handleFilterChange={handleFilterChange}
            selectedFilter={data.meta.options.filterOption}
          /> */}
          {/* <OrderSortDropdown
            handleSortChange={handleSortChange}
            selectedSort={data.meta.options.sortOption}
          /> */}
        </SearchBar>
      </div>
      <InvoiceFilterRow
        handleFilterChange={handleFilterChange}
        selectedFilter={data.meta.options.filterOption}
      />
      <ListGroup className="mt-2">
        {data.data == null ? (
          <Spinner
            className="align-self-center mt-3"
            animation="border"
            variant="primary"
          />
        ) : (
          data.data?.map((item: Invoice) => (
            <InvoiceListItem
              key={item.documentNumber}
              invoice={item}
              showCustomer={props.customerId == null}
            />
          ))
        )}
      </ListGroup>
      {data?.data != null && data.meta != null && (
        <PaginationList
          totalPages={data.meta.totalNumberOfPages || 0}
          currentPage={data.meta.currentPage || 0}
          handleChangePage={handleChangePage}
          totalItems={data.meta.totalNumberOfResults || 0}
        />
      )}
    </>
  );
}

export default InvoicesList;
