import { object, SchemaOf, string, boolean } from "yup";
import { ExtensionPropertiesOrder } from "../../services/openapi";

export const OrderExtensionPropertiesSchema: SchemaOf<ExtensionPropertiesOrder> =
  object()
    .shape({
      isDelivered: boolean().nullable(),
      lastDeliveryDate: string().nullable(),
      deliveryAfterTime: string().nullable(),
      deliveryBeforeTime: string().nullable(),
      deliveryInstructions: string().nullable(),
      deliveryContact: object().nullable(),
      pickUpAddress: string().nullable(),
      pickUpDate: string().nullable(),
      lastPickUpDate: string().nullable(),
      pickUpAfterTime: string().nullable(),
      pickUpBeforeTime: string().nullable(),
      pickUpInstructions: string().nullable(),
      pickUpContact: object().nullable(),
      yourReferenceCode: string().nullable(),
    })
    .optional();
