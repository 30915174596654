import React from "react";
import { useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import Typeahead from "react-bootstrap-typeahead/types/core/Typeahead";
import { Customer, CustomersService } from "../../services/openapi";

interface CustomerSearchInputProps {
  onCustomerSelected: (article: Customer) => void;
  id: string;
  selectedCustomerName: string | undefined | null;
  isInvalid: boolean;
}

export function CustomerSearchInput(props: CustomerSearchInputProps) {
  const [isLoadingCustomer, setIsLoadingCustomer] = useState(false);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const typeaheadref = React.createRef<Typeahead>();

  const filterBy = () => true;
  const handleSearchCustomers = (query: string) => {
    setIsLoadingCustomer(true);
    CustomersService.getApiV1Customers(1, 10, query).then((result) => {
      if (result.data) {
        setCustomers(result.data);
      } else {
        setCustomers([]);
      }
      setIsLoadingCustomer(false);
    });
  };

  return (
    <AsyncTypeahead
      isInvalid={props.isInvalid}
      id={props.id}
      options={customers}
      isLoading={isLoadingCustomer}
      onSearch={handleSearchCustomers}
      filterBy={filterBy}
      labelKey={"name"}
      ref={typeaheadref}
      className="w-75"
      onBlur={() => {
        if (!typeaheadref.current?.state.selected.length) {
          // Clear the component if not.
          typeaheadref.current?.clear();
        }
      }}
      defaultSelected={
        props.selectedCustomerName ? [props.selectedCustomerName] : undefined
      }
      onChange={(selected) => {
        if (selected.length > 0) {
          props.onCustomerSelected(selected[0] as Customer);
        } else {
          props.onCustomerSelected({} as Customer);
        }
      }}
      // @ts-ignore
      renderMenuItemChildren={(option: Customer) => (
        <div>
          <small>
            <strong>Namn:</strong> {option.name}
          </small>
          <br />
          <small>
            <strong>Kundnummer:</strong> {option.customerNumber}
          </small>
          <br />
          <small>
            <strong>Org.nr:</strong> {option.organisationNumber}
          </small>
          <small>
            <strong>Url:</strong> {option.organisationNumber}
          </small>
        </div>
      )}
      promptText="Skriv för att söka på kund"
      placeholder="Sök kund..."
      searchText="Söker..."
      emptyLabel="Inga träffar"
      useCache={false}
    />
  );
}

export default CustomerSearchInput;
