import { Button, Col, Form, InputGroup, Row, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
// import { useFetch } from "../Utils/useFetch";
import { useContext, useState } from "react";

import { faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useNavigate } from "react-router-dom";
import { AppVariableContext } from "../../AppVariableContext";
import AddressInput from "../AddressInput";
import { ArticleRowEditTable } from "../ArticleRowEditTable";
import ContactInput from "../ContactInput";
import CustomerSearchInput from "../Customer/CustomerSearchInput";
import { OrderValidationSchema } from "./../../Utils/ValidationSchemas/OrderValidationSchema";
import {
  Contact,
  ContactsService,
  Customer,
  CustomersService,
  Order,
  OrdersService,
} from "./../../services/openapi";

interface OrderFormProps {
  id?: string;
  customerId?: string;
  order?: Order;
}
const OrdersForm = (props: OrderFormProps) => {
  const extensionsettings = useContext(AppVariableContext)?.appVariables;
  const [isLoadingContact, setIsLoadingContact] = useState(false);
  const [Contacts, setContacts] = useState<Contact[]>([]);

  const handleYourRefChange = (selected: Contact) => {
    setValue("yourReference", selected?.name);
    getValues("yourReference");
  };
  const handleManualYourRefChange = (value: string) => {
    setValue("yourReference", value);
    getValues("yourReference");
  };

  const handleYourRefSearch = (nameSearch: string) => {
    setIsLoadingContact(true);
    var customerNr = getValues("customerId");
    if (customerNr) {
      ContactsService.getApiV1Contacts(1, 10, customerNr, nameSearch).then(
        (result) => {
          if (result.data) {
            //set null names to empty string
            result.data.forEach((x) => {
              if (x.name == null) x.name = "";
            });
            setContacts(result.data);
          } else {
            setContacts([]);
          }
          setIsLoadingContact(false);
        }
      );
    }
  };

  let navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
    reset,
    watch,
  } = useForm<Order>({
    resolver: yupResolver(OrderValidationSchema),
  });

  if (props.order && !getValues("documentNumber")) {
    reset(props.order);
  }

  console.log("errors", errors);
  const yourSubmitHandler = (data: Order): void => {
    console.log("submit", data);
    if (!props.order) {
      console.log("create");
      setIsSubmitting(true);
      OrdersService.postApiV1Orders(data)
        .then((response) => {
          navigate("/orders/details/" + response.documentNumber);
        })
        .catch((error: Error) => {
          alert(error.message);
          setIsSubmitting(false);
        });
    } else {
      console.log("update");
      setIsSubmitting(true);
      if (data.documentNumber) {
        OrdersService.putApiV1Orders(data.documentNumber, data)
          .then((response) => {
            navigate("/orders/details/" + response.documentNumber);
          })
          .catch((error: Error) => {
            alert(error);
            setIsSubmitting(false);
          });
      } else {
        alert("No document number");
      }
    }
  };

  const OnCustomerSelected = (customer: Customer) => {
    setValue(
      "customerId",
      customer.customerNumber ? customer.customerNumber : ""
    );
    setValue("customerName", customer.name);
    CustomersService.getApiV1Customers1(customer.customerNumber).then(
      (response) => {
        setValue("priceList", response.priceList);
      }
    );
  };

  const handleDeleteReference = (id: string) => {
    ContactsService.deleteApiV1Contacts(id).then((result) => {
      if (result) {
        //remove result.data from Addresses
        setContacts(Contacts.filter((a) => a.id !== id));
      }
    });
  };

  return extensionsettings?.extensionSettingsOrder ? (
    <Form onSubmit={handleSubmit(yourSubmitHandler)} autoComplete="off">
      <Row>
        <Col>
          <Row>
            <Col md="6">
              <Form.Group>
                <InputGroup className="mb-2">
                  <InputGroup.Text id="basic-addon-2">
                    Orderdatum
                  </InputGroup.Text>
                  <Form.Control
                    className="rounded-end"
                    type="date"
                    {...register("orderDate", {
                      required: true,
                    })}
                    defaultValue={new Date().toISOString().substring(0, 10)}
                    isInvalid={!!errors?.orderDate}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.orderDate?.message}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group>
                <InputGroup className="mb-2">
                  <InputGroup.Text id="basic-addon-2">Kund</InputGroup.Text>
                  {!!watch("customerId") && (
                    <InputGroup.Text id="basic-addon-2">
                      KundNr. {watch("customerId")}
                    </InputGroup.Text>
                  )}
                  <CustomerSearchInput
                    isInvalid={!!errors?.customerId}
                    id="customerId"
                    onCustomerSelected={(customer) => {
                      OnCustomerSelected(customer);
                    }}
                    selectedCustomerName={getValues("customerName")}
                  />
                  {/* {errors?.customerId?.message} */}
                  <Form.Control
                    type="hidden"
                    isInvalid={!!errors?.customerId}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.customerId?.message}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              {/* {errors?.customerId?.message} */}
              <Form.Group>
                <InputGroup className="mb-2">
                  <InputGroup.Text id="basic-addon-2">
                    Ert referensnummer
                  </InputGroup.Text>
                  <Form.Control
                    {...register("extensionProperties.yourReferenceCode")}
                  />
                </InputGroup>
                <Form.Control.Feedback type="invalid">
                  {errors?.extensionProperties?.yourReferenceCode?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                <InputGroup className="mb-2">
                  <InputGroup.Text id="basic-addon-2">
                    Er referens
                    <FontAwesomeIcon className="ps-1" icon={faSearch} />
                  </InputGroup.Text>
                  <AsyncTypeahead
                    id="yourReference"
                    options={Contacts}
                    onSearch={handleYourRefSearch}
                    isLoading={isLoadingContact}
                    placeholder="Referensens namn"
                    useCache={false}
                    // @ts-ignore
                    labelKey={(option: Contact) => `${option.name}`}
                    // @ts-ignore
                    renderMenuItemChildren={(option: Contact) => (
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="">{option.name}</div>
                        <div className="d-flex align-items-center">
                          {option.id && (
                            <Button
                              size="sm"
                              variant="danger"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteReference(option.id ?? "");
                              }}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          )}
                        </div>
                      </div>
                    )}
                    defaultInputValue={
                      (getValues("yourReference" as any) as string) ?? ""
                    }
                    onInputChange={(text) => handleManualYourRefChange(text)}
                    onChange={(selected) => {
                      handleYourRefChange(selected[0] as Contact);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.yourReference?.message}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group>
                <InputGroup className="mb-2">
                  <InputGroup.Text id="basic-addon-2">
                    Vår referens
                  </InputGroup.Text>
                  <Form.Control
                    className="rounded-end"
                    {...register("ourReference")}
                    isInvalid={!!errors?.ourReference}
                    defaultValue={
                      !props.id
                        ? extensionsettings?.userGivenName +
                          " " +
                          extensionsettings?.userSurname
                        : ""
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.ourReference?.message}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Check label="Makulerad" {...register("cancelled")} />
              <Form.Check label="Levererad" {...register("isDelivered")} />
            </Col>
          </Row>
          <h3>Upphämtning</h3>
          <Row className="mb-2">
            <Col className="bg-light border border-primary rounded mx-1 mx-0-md">
              <AddressInput
                recipientFieldLabel="Avsändare"
                register={register}
                fieldname="extensionProperties.pickupAddress"
                errors={errors}
                getValues={getValues}
                setValues={setValue}
              />
              <Row className="mb-2">
                <Col>
                  <Form.Group>
                    <InputGroup className="mb-2">
                      <InputGroup.Text id="basic-addon-2">
                        Anvisningar upphämtning
                      </InputGroup.Text>
                      <Form.Control
                        as="textarea"
                        {...register("extensionProperties.pickUpInstructions")}
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
              <h5>Kontakt upphämtning</h5>
              <ContactInput
                register={register}
                contacttype={"extensionProperties.pickUpContact"}
                setValues={setValue}
                getValues={getValues}
              />
              <h5 className="mt-2">Datum och tid</h5>
              <Row className="mb-2">
                <Col md="6">
                  <Row>
                    <Col md="6">
                      <Form.Group>
                        <InputGroup className="mb-2">
                          <InputGroup.Text id="basic-addon-2">
                            Tidigast/På
                          </InputGroup.Text>
                          <Form.Control
                            type="date"
                            {...register("extensionProperties.pickUpDate")}
                          />
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group>
                        <InputGroup className="mb-2">
                          <InputGroup.Text id="basic-addon-2">
                            Senast
                          </InputGroup.Text>
                          <Form.Control
                            type="date"
                            {...register("extensionProperties.lastPickUpDate")}
                          />
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col md="6">
                  <Row>
                    <Col md="6">
                      <Form.Group>
                        <InputGroup className="mb-2">
                          <InputGroup.Text id="basic-addon-2">
                            Tidigast
                          </InputGroup.Text>
                          <input
                            className="form-control"
                            type="time"
                            // pattern="[0-9]{2}:[0-9]{2}"
                            {...register("extensionProperties.pickUpAfterTime")}
                          ></input>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group>
                        <InputGroup className="mb-2">
                          <InputGroup.Text id="basic-addon-2">
                            Senast
                          </InputGroup.Text>
                          <input
                            className="form-control"
                            type="time"
                            // pattern="[0-9]{2}:[0-9]{2}"
                            {...register(
                              "extensionProperties.pickUpBeforeTime"
                            )}
                          ></input>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <h3>Leverans</h3>
          <Row className="mb-2 ">
            <Col className="border border-primary bg-light rounded mx-1 mx-0-md">
              <AddressInput
                recipientFieldLabel="Mottagare"
                register={register}
                errors={errors}
                fieldname="deliveryAddress"
                getValues={getValues}
                setValues={setValue}
              />
              <Form.Group>
                <InputGroup>
                  <InputGroup.Text id="basic-addon-2">
                    Anvisningar leverans
                  </InputGroup.Text>
                  <Form.Control
                    as="textarea"
                    className="rounded-end"
                    {...register("extensionProperties.deliveryInstructions")}
                    isInvalid={!!errors?.ourReference}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.ourReference?.message}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <h5 className="mt-2">Kontakt</h5>
              <ContactInput
                register={register}
                contacttype={"extensionProperties.deliveryContact"}
                setValues={setValue}
                getValues={getValues}
              />
              <h5 className="mt-2">Datum och tid</h5>
              <Row className="mb-2">
                <Col md="6">
                  <Row>
                    <Col md="6">
                      <Form.Group>
                        <InputGroup className="mb-2">
                          <InputGroup.Text id="basic-addon-2">
                            Tidigast/På
                          </InputGroup.Text>
                          <Form.Control
                            type="date"
                            {...register("deliveryDate")}
                          />
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group>
                        <InputGroup className="mb-2">
                          <InputGroup.Text id="basic-addon-2">
                            Senast
                          </InputGroup.Text>
                          <Form.Control
                            type="date"
                            {...register(
                              "extensionProperties.lastDeliveryDate"
                            )}
                          />
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col md="6">
                  <Row>
                    <Col md="6">
                      <Form.Group>
                        <InputGroup className="mb-2">
                          <InputGroup.Text id="basic-addon-2">
                            Tidigast
                          </InputGroup.Text>
                          <input
                            className="form-control"
                            type="time"
                            // pattern="[0-9]{2}:[0-9]{2}"
                            {...register(
                              "extensionProperties.deliveryAfterTime"
                            )}
                          ></input>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group>
                        <InputGroup className="mb-2">
                          <InputGroup.Text id="basic-addon-2">
                            Senast
                          </InputGroup.Text>
                          <input
                            className="form-control"
                            type="time"
                            // pattern="[0-9]{2}:[0-9]{2}"
                            {...register(
                              "extensionProperties.deliveryBeforeTime"
                            )}
                          ></input>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>

          <Form.Group className="mt-2">
            <InputGroup>
              <InputGroup.Text id="basic-addon-2">Anteckning</InputGroup.Text>
              <Form.Control
                as="textarea"
                className="rounded-end"
                {...register("comments")}
                isInvalid={!!errors?.comments}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.comments?.message}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Row className="py-2">
            <Col xs="6" md="4">
              <Form.Group>
                <InputGroup className="mb-2">
                  <InputGroup.Text id="basic-addon-2">
                    Bruttovikt (Kg)
                  </InputGroup.Text>
                  <Form.Control
                    {...register("grossWeightKg", {
                      setValueAs(value) {
                        if (value === "") {
                          return null;
                        }
                        return value;
                      },
                      // pattern: {
                      //   value: /^(\d+(?:[.,]\d{1,2})?)$/,
                      //   message: "Ange ett giltigt värde",
                      // },
                    })}
                    isInvalid={!!errors?.grossWeightKg}
                  />
                </InputGroup>
                {errors?.grossWeightKg?.message}
                <Form.Control.Feedback type="invalid">
                  {errors?.grossWeightKg?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs="6" md="4">
              <Form.Group>
                <InputGroup className="mb-2">
                  <InputGroup.Text id="basic-addon-2">Valuta</InputGroup.Text>
                  <Form.Select {...register("currencyCode")} defaultValue="SEK">
                    <option>SEK</option>
                    {/* <option>EUR</option>
                    <option>DKK</option>
                    <option>NOK</option>
                    <option>USD</option>
                    <option>GBP</option> */}
                  </Form.Select>
                </InputGroup>
                <Form.Control.Feedback type="invalid">
                  {errors?.currencyCode?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs="6" md="4">
              <Form.Group>
                <InputGroup className="mb-2">
                  <InputGroup.Text id="basic-addon-2">
                    Prislista
                  </InputGroup.Text>
                  <Form.Control
                    {...register("priceList")}
                    disabled={true}
                  ></Form.Control>
                </InputGroup>
                <Form.Control.Feedback type="invalid">
                  {errors?.priceList?.message}
                </Form.Control.Feedback>
                <span>Prislistan hämtas från kund</span>
              </Form.Group>
            </Col>
            <Col>
              <h4>Orderrader</h4>
              <ArticleRowEditTable
                setValue={setValue}
                register={register}
                arrayName="rows"
                getValues={getValues}
                watch={watch}
              />
            </Col>
          </Row>
          <div className="d-flex justify-content-around mt-3">
            <Button variant="success" type="submit" disabled={isSubmitting}>
              {props.id === undefined ? "Skapa" : "Spara"}
              {isSubmitting && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="ms-2"
                />
              )}
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  ) : (
    <></>
  );
};

export default OrdersForm;
