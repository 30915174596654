/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Invoice } from '../models/Invoice';
import type { InvoiceFilterOption } from '../models/InvoiceFilterOption';
import type { InvoiceList } from '../models/InvoiceList';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InvoiceService {

    /**
     * @param page 
     * @param pageSize 
     * @param customerId 
     * @param descending 
     * @param filterBy 
     * @returns InvoiceList Success
     * @throws ApiError
     */
    public static getApiV1Invoice(
page: number = 1,
pageSize: number = 30,
customerId: string = '',
descending: boolean = false,
filterBy?: InvoiceFilterOption,
): CancelablePromise<InvoiceList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Invoice',
            query: {
                'page': page,
                'pageSize': pageSize,
                'customerId': customerId,
                'descending': descending,
                'filterBy': filterBy,
            },
        });
    }

    /**
     * @param id 
     * @returns Invoice Success
     * @throws ApiError
     */
    public static getApiV1Invoice1(
id: string,
): CancelablePromise<Invoice> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Invoice/{id}',
            path: {
                'id': id,
            },
        });
    }

}
