import { ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Invoice } from "./../../services/openapi";

interface InvoiceListItemProps {
  invoice: Invoice;
  showCustomer?: boolean;
}

export const InvoiceListItem = (props: InvoiceListItemProps) => (
  <ListGroup.Item
    key={props.invoice.documentNumber}
    as={Link}
    to={"/invoices/details/" + props.invoice.documentNumber}
    className="d-flex justify-content-between align-items-center"
    action
  >
    <div>
      <h5 className="mb-0">
        {props.invoice.documentNumber}
        {props.showCustomer && " - " + props.invoice.customerName}
        {/* {props.invoice.sent ? (
          <Badge bg="secondary" className="ms-1">
            Skickad
          </Badge>
        ) : null} */}
        {/* {props.invoice.paid ? (
          <Badge bg="success" className="ms-1">
            Betald
          </Badge>
        ) : (
          <Badge bg="danger" className="ms-1">
            Obetald
          </Badge>
        )}
        {props.invoice.cancelled ? (
          <Badge bg="warning" className="ms-1">
            Makulerad
          </Badge>
        ) : null}
        {props.invoice.booked ? (
          <Badge bg="light" className="ms-1 text-dark">
            Bokförd
          </Badge>
        ) : (
          <Badge bg="light" className="ms-1 text-danger">
            Ej bokförd
          </Badge>
        )} */}
      </h5>
      <span>{props.invoice.invoiceDate} </span>
      <div></div>
    </div>
    <div>
      <span>
        {props.invoice.amount} {props.invoice.currencyCode}
      </span>
    </div>
  </ListGroup.Item>
);
