import { useState, useEffect } from "react";
import { Container, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Order, OrdersService } from "../../services/openapi";
import OrdersForm from "./OrdersForm";

const OrdersEdit = () => {
  const params = useParams();

  const [data, setData] = useState<Order>();

  useEffect(() => {
    if (params.id) {
      OrdersService.getApiV1Orders1(params.id).then((response) => {
        setData(response);
      });
    }
  }, [params.id]);

  return data ? (
    <Container fluid="lg">
      <h2>Ändra order: {params.id}</h2>
      <OrdersForm id={params.id} order={data} />
    </Container>
  ) : (
    <Spinner animation="border" />
  );
};

export default OrdersEdit;
