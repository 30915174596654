import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AppVariables, AppVariablesService } from "./services/openapi";
import { AppVariableContext } from "./AppVariableContext";

const AppVariableContextProvider = (props: { children: JSX.Element }) => {
  const [vars, setVars] = useState<AppVariables | null>(null);

  useEffect(() => {
    const getNewSettings = async () => {
      AppVariablesService.getApiV1AppVariables().then((response) => {
        setVars(response);
      });
    };
    const setRepeatCall = () => {
      const timeout = setInterval(() => {
        getNewSettings();
      }, 180000);
      return () => clearInterval(timeout);
    };
    getNewSettings();
    setRepeatCall();
  }, []);

  const updateAppVariables = (newVars: AppVariables) => {
    AppVariablesService.putApiV1AppVariables(newVars).then((response) => {
      setVars(response);
    });
  };

  if (vars) {
    return (
      <AppVariableContext.Provider
        value={{ appVariables: vars, setAppVariables: updateAppVariables }}
      >
        {props.children}
      </AppVariableContext.Provider>
    );
  } else {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <img
          src="https://tidrappisol.blob.core.windows.net/sella/Asset 3.svg"
          alt="logo"
          style={{ width: 70 }}
          className="spin "
        />
      </div>
    );
  }
};

AppVariableContextProvider.propTypes = {
  children: PropTypes.node,
};

export { AppVariableContextProvider };
